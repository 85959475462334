import React, { FC } from 'react';

import { twMerge } from 'tailwind-merge';
import { cva, VariantProps } from 'class-variance-authority';

const variants = cva('font-normal text-base', {
  variants: {
    variant: {
      h1: 'text-4xl',
      h2: 'text-3xl',
      h0: 'text-2.5xl',
      h3: 'text-2xl', // 24px
      h4: 'text-xl',
      h5: 'text-lg', // 17px
      h6: 'text-base', // 15px
      body1: 'text-sm', // 13px
      body2: 'text-xs', // 11px
    },
    color: {
      black: 'text-k-black',
      grey: 'text-k-grey-text',
      white: 'text-white',
      blue: 'text-k-dark-blue',
      blueMedium: 'text-k-blue',
      blueDark: 'text-k-blue-text',
      lightBlue: 'text-k-light-blue-300',
    },
  },
  defaultVariants: {
    variant: 'h6',
    color: 'black',
  },
});

interface TypographyProps extends VariantProps<typeof variants> {
  className?: string;
  children?: React.ReactNode;
  onClick?: () => void;
}

const Typography: FC<TypographyProps> = ({
  variant,
  color,
  className,
  children,
  onClick,
}) => {
  return (
    <p
      className={twMerge(variants({ variant, color, className }))}
      onClick={() => {
        typeof onClick === 'function' && onClick();
      }}
    >
      {children}
    </p>
  );
};

export default Typography;
