import React, { FC, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../../store';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';

import Typography from '../../../components/Typography/Typography';
import Button from '../../../components/Button/Button';
import ASSETS from '../../../assets';
import { ITEMS } from '../Documents';

dayjs.extend(relativeTime);
dayjs.extend(utc);

interface IProps {
  setTab: React.Dispatch<React.SetStateAction<ITEMS>>;
}

const KPMGScan: FC<IProps> = ({ setTab }) => {
  const dispatch = useDispatch<Dispatch>();

  const { KPMGScan, searchedAttestation } = useSelector(
    (state: RootState) => state.attesation
  );
  const matchedKPMGScan = KPMGScan?.filter((searchedObj: { UID: string }) =>
    searchedAttestation?.attestationData?.some(
      (myObj: { UID: string }) => myObj.UID === searchedObj.UID
    )
  );
  const finalKPMGScans = searchedAttestation ? matchedKPMGScan : KPMGScan;
  const handleViewAllAttestations = () => {
    setTab('all-attesations');
  };

  useEffect(() => {
    dispatch.attesation.handleGetKPMGScan();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <div className='w-full overflow-auto'>
        <table className='w-full border-collapse'>
          <thead>
            <tr className='[&_th]:text-sm [&_th]:text-k-grey-text [&_th]:font-medium [&_th]:text-left [&_th]:border-b [&_th]:border-b-k-border [&_th]:h-[37px]'>
              <th className='pl-[15px]'>UID</th>
              <th>Schema</th>
              <th>Creator</th>
              <th>Type</th>
              <th className='pr-[15px]'>Age</th>
            </tr>
          </thead>
          <tbody>
            {finalKPMGScans?.map(attestation => (
              <tr
                key={attestation.UID}
                className='[&_td]:h-[55px] [&_td]:border-b [&_td]:border-b-k-border [&:last-of-type_td]:border-none'
              >
                <td className='pl-[15px]'>
                  <a
                    href={attestation.verifyOnEAS}
                    rel='noopener noreferrer'
                    target='_blank'
                  >
                    <Typography className='truncate w-[27ch] text-k-dark-blue'>
                      {attestation.UID}
                    </Typography>
                  </a>
                </td>
                <td>
                  <div className='flex'>
                    <div className='bg-k-dark-blue text-sm text-white px-[5px] py-[2px] rounded-l-[4px]'>
                      #145
                    </div>
                    <div className='text-sm text-k-blue semibold px-[5px] py-[1px] border border-k-light-blue-300 bg-[#D4EBFF] rounded-r'>
                      Endorsements
                    </div>
                  </div>
                </td>
                <td>
                  <a
                    href={`https://base-sepolia.blockscout.com/address/${attestation.creator}`}
                    rel='noopener noreferrer'
                    target='_blank'
                  >
                    <Typography className='truncate w-[27ch] text-k-dark-blue'>
                      {attestation.creator}
                    </Typography>
                  </a>
                </td>
                <td>
                  <Typography variant='h6' color='grey' className=''>
                    {attestation.type}
                  </Typography>
                </td>
                <td>
                  <Typography variant='h6' color='grey' className=''>
                    {dayjs.unix(+attestation.time).utc().fromNow()}
                  </Typography>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className='flex justify-center mt-10'>
        <Button
          label='View All Attestations'
          leftIcon={ASSETS.add_black}
          onClick={handleViewAllAttestations}
        />
      </div>
    </div>
  );
};

export default KPMGScan;
