import { createModel } from '@rematch/core';
import { ATTESATION } from '../../types/types';

import { RootModel } from '.';
import {
  createHashWithDoc,
  createHashWithText,
  getAllAttesations,
  getKPMGScan,
  getMyAttestations,
  searchAttestation,
  wordToPDF,
} from '../../http';

import { IAttessation } from '../../types/interfaces';

export type ITEMS = "my-attesations" | "all-attesations" | "kpmg-scan";

interface IState {
  attesationType: ATTESATION;
  doc_hash: string;
  text_hash: string;
  loading: boolean;
  searchLoading: boolean;
  activeTab: ITEMS,
  word_PDF: any;
  searchedAttestation: any;
  allAttesations: IAttessation[];
  selectedAttestation: IAttessation | null;
  myAttestations: IAttessation[];
  KPMGScan: IAttessation[];
}

export const attesation = createModel<RootModel>()({
  name: 'attesation',
  state: {
    attesationType: 'text',
    doc_hash: '',
    text_hash: '',
    word_PDF: '',
    activeTab: 'my-attesations',
    loading: false,
    searchedAttestation: null,
    allAttesations: [],
    searchLoading: false,
    selectedAttestation: null,
    myAttestations: [],
    KPMGScan: [],
  } as IState,
  reducers: {
    setAttesationType(state, payload: ATTESATION) {
      state.attesationType = payload;
    },
    setActiveTab(state, payload: ITEMS) {
      state.activeTab = payload;
    },
    setDocHash(state, payload: string) {
      state.doc_hash = payload;
    },
    setWordPDF(state, payload: any) {
      state.word_PDF = payload;
    },
    setTextHash(state, payload: string) {
      state.text_hash = payload;
    },
    setSearchedAttestation(state, payload: any) {
      state.searchedAttestation = payload;
    },
    setSearchLoading(state, payload: boolean) {
      state.searchLoading = payload;
    },
    setLoading(state, payload: boolean) {
      state.loading = payload;
    },
    setAllAttesations(state, payload: IAttessation[]) {
      state.allAttesations = payload;
    },
    setSelectedAttestation(state, payload: IAttessation | null) {
      state.selectedAttestation = payload;
    },
    setMyAttestations(state, payload: IAttessation[]) {
      state.myAttestations = payload;
    },
    setKPMGScan(state, payload: IAttessation[]) {
      state.KPMGScan = payload;
    },
  },
  effects: dispatch => ({
    async handleGenerateDocHash(payload: FormData) {
      try {
        dispatch.attesation.setLoading(true);
        const { data } = await createHashWithDoc(payload);
        dispatch.attesation.setDocHash(data.hash);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.attesation.setLoading(false);
      }
    },
    async handleWordToPDF(payload: FormData) {
      try {
        dispatch.attesation.setLoading(true);
        const { data } = await wordToPDF(payload);
        console.log(data);
        dispatch.attesation.setWordPDF(data);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.attesation.setLoading(false);
      }
    },
    async handleGenerateTextHash(payload: string) {
      try {
        dispatch.attesation.setLoading(true);
        const { data } = await createHashWithText(payload);
        dispatch.attesation.setTextHash(data.hash);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.attesation.setLoading(false);
      }
    },
    async handleSearchAttestation(payload?: { input?: string }) {
      try {
        dispatch.attesation.setSearchLoading(true);
        const UID = payload?.input || null;
        const { data } = await searchAttestation(UID);
        dispatch.attesation.setSearchedAttestation(data);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.attesation.setSearchLoading(false);
      }
    },
    async handleGetAllAttesations() {
      try {
        dispatch.attesation.setLoading(true);
        const { data } = await getAllAttesations();
        dispatch.attesation.setAllAttesations(data.attestationData);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.attesation.setLoading(false);
      }
    },
    async handleGetMyAttestations() {
      try {
        dispatch.attesation.setLoading(true);
        const { data } = await getMyAttestations();
        dispatch.attesation.setMyAttestations(data.attestationData);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.attesation.setLoading(false);
      }
    },
    async handleGetKPMGScan() {
      try {
        dispatch.attesation.setLoading(true);
        const { data } = await getKPMGScan();
        dispatch.attesation.setKPMGScan(data.attestationData);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.attesation.setLoading(false);
      }
    },
  }),
});
