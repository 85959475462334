import React, { FC } from 'react';

import { cva, VariantProps } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';
import { ClipLoader } from 'react-spinners';

const variants = cva(
  'text-base semibold rounded-3xl justify-center flex items-center gap-2 px-[18px] py-3 leading-[1] h-[45px]',
  {
    variants: {
      variant: {
        primary:
          'bg-k-dark-blue !text-white hover:bg-[#0734A9] transition-all disabled:bg-[#E5E5EA] disabled:cursor-not-allowed',
        outlined: 'border border-k-light-blue hover:bg-[#00000005]',
      },
      textColor: {
        white: 'text-white',
        blue: 'text-k-light-blue',
        black: 'text-k-black',
      },
    },
    defaultVariants: {
      variant: 'outlined',
      textColor: 'black',
    },
  }
);

interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof variants> {
  label: string;
  leftIcon?: string;
  rightIcon?: string;
  loading?: boolean;
}

const Button: FC<ButtonProps> = ({
  variant,
  textColor,
  label,
  className,
  leftIcon,
  rightIcon,
  loading,
  disabled,
  ...rest
}) => {
  return (
    <button
      className={twMerge(variants({ variant, textColor, className }))}
      {...rest}
      disabled={loading || disabled}
    >
      {loading ? (
        <ClipLoader
          size={25}
          color={variant === 'primary' ? '#ffffff' : '#030A74'}
        />
      ) : (
        <>
          {leftIcon && <img src={leftIcon} alt='' />}
          {label}
          {rightIcon && <img src={rightIcon} alt='' />}
        </>
      )}
    </button>
  );
};

export default Button;
