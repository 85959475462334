import React, { useState, useRef, FC } from 'react';

import { twMerge } from 'tailwind-merge';

import DialogLayout from '../DialogLayout';
import Typography from '../../components/Typography/Typography';
import Button from '../../components/Button/Button';

import ASSETS from '../../assets';
import { RootState } from '../../store';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  attestationTitle?: string;
  doc?: any;
  version?: number;
  onClose?: () => void;
  refUID?: string;
}

const SignatureRequest: FC<IProps> = ({
  open,
  setOpen,
  onClose,
  attestationTitle,
  doc,
  refUID,
  version,
}) => {
  const [openPanel, setOpenPanel] = useState(true);
  console.log('doc', doc);
  const { attesationType } = useSelector(
    (state: RootState) => state.attesation
  );
  const { user } = useSelector((state: RootState) => state.auth);
  const panel = useRef<HTMLDivElement>(null);

  const arrow = useRef<HTMLImageElement>(null);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSign = () => {
    setOpen(false);
    if (onClose) onClose();
  };

  const handleAccordion = () => {
    if (panel.current && arrow.current) {
      if (panel.current.style.maxHeight > '0px') {
        panel.current.style.maxHeight = '0px';
        panel.current.classList.remove('open');
        arrow.current.style.rotate = '180deg';
        setOpenPanel(false);
      } else {
        panel.current.style.maxHeight = panel.current.scrollHeight + 'px';
        panel.current.classList.add('open');
        arrow.current.style.rotate = '0deg';
        setOpenPanel(true);
      }
    }
  };

  return (
    <DialogLayout open={true}>
      <div className='w-full max-w-[536px] px-[50px] py-[45px] bg-white rounded-[15px] flex flex-col items-center relative  max-h-[90vh] overflow-y-auto hide-scrollbar'>
        <div
          className='absolute top-[25px] right-[25px] rounded-full size-[30px] bg-[#0000000D] cursor-pointer grid place-items-center'
          onClick={handleClose}
        >
          <img src={ASSETS.cross} alt='' />
        </div>
        <div className='pb-[40px]'>
          <Typography
            variant='h0'
            className={twMerge(
              'text-center transition-all title font-bold',
              !openPanel && 'mt-[53px]'
            )}
          >
            Signature Request
          </Typography>
          <Typography
            variant='h5'
            className={twMerge(
              'text-center mt-[10px] transition-all font-medium',
              openPanel ? 'mb-10' : 'mb-[95px]'
            )}
          >
            Only sign this message if you fully understand the content and trust
            the requesting site.
          </Typography>
        </div>

        <div className='w-full'>
          <div
            className='border-t border-k-border w-full h-12 flex items-center justify-between cursor-pointer'
            onClick={handleAccordion}
          >
            <Typography variant='body1' className='semibold'>
              Signature Details
            </Typography>
            <img
              src={ASSETS.chevron_down}
              className='transition-all duration-300'
              ref={arrow}
              alt=''
            />
          </div>
          <div
            ref={panel}
            className='panel max-h-0 overflow-hidden transition-all duration-300'
          >
            {attesationType === 'document' && (
              <>
                <Typography variant='body1' color='grey'>
                  Date Created:
                </Typography>
                <Typography
                  variant='h6'
                  className='break-words font-medium mb-[10px]'
                >
                  {dayjs().local().format('MMM D, YYYY @ h:mm A')}
                </Typography>
              </>
            )}
            <Typography variant='body1' color='grey'>
              Schema:
            </Typography>
            <Typography variant='h6' className='break-words font-medium'>
              0xe12253b0bd0fb3462821600ed88413803ac648235c7757682d2463a10cd3034a
            </Typography>
            <Typography variant='body1' color='grey' className='mt-[10px]'>
              Recipient:
            </Typography>
            <Typography variant='h6' className='break-words font-medium'>
              {user?.walletAddress}
            </Typography>
            <Typography variant='body1' color='grey' className='mt-[10px]'>
              Time:
            </Typography>
            <Typography variant='h6' className='break-words font-medium'>
              {dayjs().unix()}
            </Typography>
            <Typography variant='body1' color='grey' className='mt-[10px]'>
              ExpirationTime:
            </Typography>
            <Typography variant='h6' className='break-words font-medium'>
              0
            </Typography>
            <Typography variant='body1' color='grey' className='mt-[10px]'>
              Revocable:
            </Typography>
            <Typography variant='h6' className='break-words font-medium'>
              True
            </Typography>
            <Typography variant='body1' color='grey' className='mt-[10px]'>
              Ref UID:
            </Typography>
            <Typography variant='h6' className='break-words font-medium'>
              {refUID ||
                '0x0000000000000000000000000000000000000000000000000000000000000000'}
            </Typography>
            <div className='h-5'></div>
          </div>
        </div>

        <div className='bg-k-grey-200 px-[18px] pt-[17px] pb-[21px] w-full rounded-lg flex items-center justify-between'>
          <div>
            <Typography variant='body1' color='grey'>
              {attesationType === 'text' ? 'Attestation Name:' : 'File Name:'}
            </Typography>
            <Typography
              variant='body1'
              className='semibold max-w-[25ch] overflow-hidden overflow-ellipsis'
            >
              {attesationType === 'text' ? attestationTitle : doc?.name}
            </Typography>
          </div>
          <div>
            {attesationType !== 'text' && (
              <>
                <Typography variant='body1' color='grey'>
                  Version:
                </Typography>
                <Typography variant='body1' className='semibold'>
                  {version ? version + 1 : 1}
                </Typography>
              </>
            )}
          </div>
          <div>
            <Typography variant='body1' color='grey'>
              File Size:
            </Typography>
            <Typography variant='body1' className='semibold'>
              {Math.floor((doc?.size || 0) / 1024)}k
            </Typography>
          </div>
        </div>
        <div className='flex items-center gap-[15px] w-full mt-[35px]'>
          <Button label='Reject' className='flex-1' onClick={handleClose} />
          <Button
            label='Accept & Sign'
            variant='primary'
            className='flex-1'
            onClick={handleSign}
          />
        </div>
      </div>
    </DialogLayout>
  );
};

export default SignatureRequest;
