import React, { useState } from 'react';

import { Outlet, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

import Typography from '../../components/Typography/Typography';
import ASSETS from '../../assets';
import Button from '../../components/Button/Button';
import Profile from '../../dialogs/Profile/Profile';

const DashboardLayout = () => {
  const [openProfile, setOpenProfile] = useState(false);

  const { user } = useSelector((state: RootState) => state.auth);

  const handleProfile = () => {
    setOpenProfile(true);
  };

  const navigate = useNavigate();
  const onClickHandler = () => {
    navigate(`/documents`);
  }

  return (
    <div className='min-h-screen bg-white flex flex-col'>
      <div className='h-[168px]  bg-g_gradient'>
        <div className='h-full  pb-[51px] flex items-center justify-center gap-5 bg-f_gradient'>
          <div className='w-full max-w-[980px] px-5 flex items-center justify-between'>
            <div className='flex items-center gap-5 cursor-pointer' onClick={onClickHandler}>
              <img src={ASSETS.kpmg_logo} alt='' />
              <Typography variant='h3' color='white'>
                +
              </Typography>
              <img src={ASSETS.kresus_logo} alt='' />
            </div>
            <div className='flex items-center gap-[25px]'>
              <div className='flex flex-col items-end'>
                {user?.firstName || user?.lastName ? (
                  <Typography variant='h6' color='white'>
                    {user?.firstName} {user?.lastName}
                  </Typography>
                ) : null}

                <Typography variant='body1' color='lightBlue'>
                  KPMG
                </Typography>
              </div>
              <Button
                label='Profile'
                textColor='white'
                leftIcon={ASSETS.settings}
                onClick={handleProfile}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='flex-grow flex flex-col w-full bg-white '>
        <div className='flex-grow w-full max-w-[980px] mx-auto mt-[-51px] flex flex-col'>
          <Outlet />
        </div>
      </div>
      {openProfile && <Profile open={openProfile} setOpen={setOpenProfile} />}
    </div>
  );
};

export default DashboardLayout;
