import { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useMagic } from '../../contexts/MagicContext';
import { useDispatch } from 'react-redux';
import { Dispatch } from '../../store';

import { object, string } from 'yup';
import { useFormik } from 'formik';

import Typography from '../../components/Typography/Typography';
import TextField from '../../components/TextField/TextField';
import { ClipLoader } from 'react-spinners';
import { IUser } from '../../types/interfaces';
import { emailVerification, onBoarding } from '../../http';
import CONSTANTS from '../../contants';

const schema = object({
  email: string()
    .email()
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      'Invalid email address'
    )
    .test(
      'no-multiple-tlds',
      'Invalid email address with multiple TLDs',
      value => {
        if (!value) return false;

        const domainPart = value.split('@')[1];
        if (!domainPart) return false;

        const tlds = domainPart.split('.');
        const lastTLD = tlds[tlds.length - 1];

        return tlds.indexOf(lastTLD) === tlds.length - 1 && tlds.length < 4;
      }
    )
    .required(),
});

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();
  const { magic } = useMagic();

  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: schema,
    onSubmit: async values => {
      try {
        console.log('Sending email otp');
        setLoading(true);
        formik.setErrors({ email: '' });
        const email = values.email;
        const { data } = await emailVerification(email);
        if (!data.status) {
          setLoading(false);
          formik.setErrors({ email: 'This email is not Allowd.' });
          return;
        }
        const token: any = magic?.auth.loginWithEmailOTP({
          email,
          showUI: false,
          lifespan: 604800,
        });
        dispatch.auth.setEmail(email);
        setLoading(true);
        token
          .on('email-otp-sent', () => {
            console.log('email-otp-sent ---------', token);
            dispatch.auth.setTokenPromise(token);
            navigate('/verify-email');
          }) // @ts-ignore
          ?.on('error', reason => {
            formik.setErrors({
              email: 'Please provide a valid email address',
            });
            setLoading(false);
          })
          .on('settled', async () => {
            console.log('email-otp-sent-settled ---------', token);

            const userInfo = await magic?.user.getInfo();
            console.log(`UserInfo: ${JSON.stringify(userInfo)}`);
            const metadata = await magic?.user.getMetadata();
            const _token = await magic?.user.getIdToken();
            console.log(metadata, _token);

            if (metadata?.publicAddress && _token) {
              const user: IUser = {
                walletAddress: metadata.publicAddress,
                email: metadata.email!,
                firstName: null,
                lastName: null,
              };
              const { data } = await onBoarding(_token);
              localStorage.setItem(CONSTANTS.token, data.access_token);
              dispatch.auth.setUser(user);
              dispatch.auth.setIsLoggedIn(true);
              navigate('/documents');
            }

            // TODO: Call backend endpoint

            // saveUserInfo(_token ?? '', 'EMAIL', metadata?.publicAddress ?? ''); //storing here
            // localStorage.setItem('user', JSON.stringify(metadata));
            // router.push('./designYourBadge');
          });
      } catch (err: any) {
        console.log(err.message);
      } finally {
        // setLoading(false);
      }
    },
  });

  return (
    <div className='flex flex-col items-center pt-[90px] px-[188px] h-[638px]'>
      <Typography variant='h1' className='title'>
        Log In
      </Typography>
      <Typography
        variant='h3'
        className='max-w-[35ch] text-center font-[264] mt-[25px]'
      >
        Immutable and verifiable attestation creation powered by the blockchain.
      </Typography>
      <Typography color='grey' className='mt-10'>
        Enter your email below to log in:
      </Typography>
      <TextField
        label='Email'
        type='email'
        name='email'
        value={formik.values.email}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        showButton
        onSubmit={formik.handleSubmit}
        className='min-w-[488px] mt-[15px]'
        buttonDisabled={loading}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            formik.handleSubmit();
          }
        }}
        error={
          formik.touched.email && formik.errors.email ? formik.errors.email : ''
        }
        onClear={() => formik.setFieldValue('email', '')}
      />
      {formik.errors.email && (
        <Typography className='text-k-error mt-2'>
          Please enter a valid authorized email address.
        </Typography>
      )}
      {loading && (
        <div className='flex items-center justify-center mt-5'>
          <ClipLoader size={30} color='#030A74' />
        </div>
      )}
    </div>
  );
};

export default Login;
