import React, { FC, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { RootState, Dispatch } from "../../../store";

import Attestation from "./Attestation";
import { IAttessation } from "../../../types/interfaces";
import Typography from "../../../components/Typography/Typography";
import { ClipLoader } from "react-spinners";
interface IProps {
  searchLoading: boolean;
}
const AllAttesations: FC<IProps> = ({searchLoading}) => {
  const dispatch = useDispatch<Dispatch>();

  const { allAttesations, searchedAttestation, loading } = useSelector(
    (state: RootState) => state.attesation
  );

  useEffect(() => {
    dispatch.attesation.handleGetAllAttesations();
    // eslint-disable-next-line
  }, []);
  const finalAttestations = searchedAttestation
    ? searchedAttestation.attestationData
    : allAttesations;
  return (
    <>
      {searchLoading && (
        <div className="flex-grow h-1 min-h-[300px]">
        <div className="h-full bg-k-grey-bg border border-k-border rounded-[12px] flex flex-col items-center justify-center">
          <ClipLoader size={30} color="#030A74" />
        </div>
        </div>
      )}
      {finalAttestations.length > 0 && !searchLoading ? (
        <div className="flex flex-col">
          {finalAttestations.map((attesation: IAttessation) => (
            <Attestation attestation={attesation} key={attesation.UID} />
          ))}
        </div>
      ) : (
        !searchLoading &&  (
          <div className="flex-grow h-1 min-h-[300px]">
            <div className="h-full bg-k-grey-bg border border-k-border rounded-[12px] flex flex-col items-center justify-center">
              <Typography variant="h4">No Attestation Found</Typography>
            </div>
          </div>
        )
      )}
    </>
  );
};

export default AllAttesations;
