import React, { useState, useRef, useEffect } from 'react';

import { twMerge } from 'tailwind-merge';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../store';

import Typography from '../../components/Typography/Typography';
import Button from '../../components/Button/Button';
import MyAttesations from './components/MyAttesations';
import AllAttesations from './components/AllAttesations';
import KPMGScan from './components/KPMGScan';

import ASSETS from '../../assets';

export type ITEMS = 'my-attesations' | 'all-attesations' | 'kpmg-scan';

const Documents = () => {
  const slider = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [isSearched, setIsSearched] = useState(false);

  const handleChange = (e: any) => {
    const value = e.target.value;
    setSearchTerm(value);
  };

  const dispatch = useDispatch<Dispatch>();

  const { allAttesations, myAttestations, searchedAttestation, activeTab, searchLoading } =
    useSelector((state: RootState) => state.attesation);

  const [active, setActive] = useState<ITEMS>(activeTab);
  const handleChangeActive = (val: ITEMS) => {
    setActive(val);
    dispatch.attesation.setActiveTab(val);
  };

  const handleAddDocument = () => {
    navigate('/add-document');
  };
  const handleSearchClick = () => {
    if (searchTerm) {
      dispatch.attesation.handleSearchAttestation({ input: searchTerm });
    } else {
      dispatch.attesation.handleSearchAttestation();
    }
    setIsSearched(true);
  };

  const handleSearchEmpty = () => {
      dispatch.attesation.handleSearchAttestation();
      setSearchTerm('')
      setIsSearched(true);
  };

  

  useEffect(() => {
    dispatch.attesation.handleGetAllAttesations();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (slider.current) {
      if (active === 'my-attesations') {
        slider.current.style.left = '3px';
      } else if (active === 'kpmg-scan') {
        slider.current.style.left = 'calc(66.66% - 3px)';
      } else {
        slider.current.style.left = 'calc(33.33%)';
      }
    }
  }, [active]);

  const matchedAttestations = searchedAttestation?.attestationData.filter(
    (searchedObj: { UID: string }) =>
      myAttestations?.some(myObj => myObj.UID === searchedObj.UID)
  );
  return (
    <div className='flex-grow bg-white shadow-auth_box rounded-[15px] p-[50px] flex flex-col mb-[104px]'>
      <div className='flex items-center justify-between'>
        <div>
          <Typography variant='h2' className='font-light leading-[40px]'>
            Attestations
          </Typography>
          <Typography variant='h6' color='grey'>
            Click on any document below to view details, add revisions, and
            share the EAS hash.
          </Typography>
        </div>
        <Button
          label='Add New'
          variant='primary'
          leftIcon={ASSETS.add}
          onClick={handleAddDocument}
        />
      </div>
      <div className='mt-10'>
        <div className='flex items-center gap-[15px] p-[3px]'>
          <div className='flex flex-grow h-[52px] bg-k-grey-bg rounded-3xl border border-k-border relative'>
            <div
              className='flex-1 flex items-center justify-center cursor-pointer'
              onClick={() => handleChangeActive('my-attesations')}
            >
              <Typography
                variant='h6'
                color={active === 'my-attesations' ? 'black' : 'grey'}
                className={twMerge(
                  'z-10 transition-all',
                  active === 'my-attesations' ? 'semibold' : 'font-medium'
                )}
              >
                My Attestations (
                {isSearched
                  ? matchedAttestations?.length
                  : myAttestations.length || '0'}
                )
              </Typography>
            </div>
            <div
              className='flex-1 flex items-center justify-center  cursor-pointer'
              onClick={() => handleChangeActive('all-attesations')}
            >
              <Typography
                variant='h6'
                color={active === 'all-attesations' ? 'black' : 'grey'}
                className={twMerge(
                  'z-10 transition-all',
                  active === 'all-attesations' ? 'semibold' : 'font-medium'
                )}
              >
                All Attestations (
                {isSearched
                  ? searchedAttestation?.attestationData?.length
                  : allAttesations.length || 0}
                )
              </Typography>
            </div>
            <div
              className='flex-1 flex items-center justify-center  cursor-pointer'
              onClick={() => handleChangeActive('kpmg-scan')}
            >
              <Typography
                variant='h6'
                color={active === 'kpmg-scan' ? 'black' : 'grey'}
                className={twMerge(
                  'z-10 transition-all',
                  active === 'kpmg-scan' ? 'semibold' : 'font-medium'
                )}
              >
                KPMG Scan
              </Typography>
            </div>
            <div
              ref={slider}
              className='slider w-[calc(33.33%)] absolute left-[3px] top-[3px] h-[calc(100%_-_6px)] rounded-3xl bg-white border border-k-border transition-all duration-300 shadow-tab_shadow'
            ></div>
          </div>
          <div className={twMerge('min-w-[331px] h-[52px] bg-k-grey-bg rounded-3xl border border-k-border flex items-center overflow-hidden', searchTerm ? 'pr-[2px]' : 'pr-4' )}>
            <input
              type='text'
              className='flex-grow h-full outline-none border-none bg-transparent px-4 text-base placeholder:text-k-grey-text'
              placeholder='Attestation, UID, schema or address'
              value={searchTerm}
              onChange={handleChange}
            />
            <div className='flex flex-row gap-3'>
            { searchTerm && (<img
              src={ASSETS.cross}
              alt='search'
              className='cursor-pointer'
              onClick={handleSearchEmpty}
            />)}
            <img
              src={searchTerm ? ASSETS.search_white : ASSETS.search}
              alt='search'
              className={twMerge('cursor-pointer', searchTerm && 'bg-k-medium-blue cursor-pointer rounded-full p-4 w-[46px] h-[46px]')}
              onClick={handleSearchClick}
            />
            </div>

          </div>
        </div>
      </div>
      <div className='mt-5'>
        {active === 'kpmg-scan' && <KPMGScan setTab={setActive} />}
        {active === 'all-attesations' && <AllAttesations searchLoading={searchLoading} />}
      </div>

      {active === 'my-attesations' && <MyAttesations searchLoading={searchLoading} />}
    </div>
  );
};

export default Documents;
