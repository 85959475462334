import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import Typography from '../../components/Typography/Typography';
import DocumentHistory from './DocumentHistory';
import { useNavigate } from 'react-router-dom';
import DocumentDetails from './DocumentDetails';
import ASSETS from '../../assets';
import { IAttessation } from '../../types/interfaces';
import { getAttestationByUid } from '../../http';

const OtherUserDoc = () => {
  const navigate = useNavigate();
  const id = useParams()?.id;

  const [attestation, setAttestation] = useState<IAttessation | null>(null);
  const [current, setCurrent] = useState<IAttessation | null>(null);
  const handleGetAttestaion = async () => {
    try {
      const { data } = await getAttestationByUid(id!);
      setAttestation(data.attestationData);
    } catch (err: any) {}
  };

  const handleClick = () => {
    navigate('/documents');
  };

  useEffect(() => {
    if (id) {
      handleGetAttestaion();
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (attestation) {
      if (attestation.latestAttestation) {
        setCurrent(attestation.latestAttestation);
      } else {
        setCurrent(attestation);
      }
    }
    // eslint-disable-next-line
  }, [attestation]);
  return (
    <>
      <div className='flex-grow bg-white shadow-auth_box rounded-[15px] p-[50px] pt-[25px] flex flex-col mb-[15px]'>
        <div
          className='flex gap-2 pb-[16px] '
        >
          <div className='flex flex-row gap-2 cursor-pointer' onClick={handleClick}>
          <img src={ASSETS.arrow_left} alt='' />
          <Typography variant='body1' color='blue' className='semibold text-sm'>
            Dashboard
          </Typography>
          </div>
        </div>
        {attestation && current && (
          <DocumentDetails
            latesUID={attestation?.latestAttestation?.UID || ''}
            attestation={current}
            attestations={attestation?.attestations}
          />
        )}
      </div>
      {!!attestation?.totalVersions && (
        <div className='flex-grow bg-white shadow-auth_box rounded-[15px] p-[50px] flex flex-col mb-[104px]'>
          <div className='flex justify-between border-b border-b-k-border pb-[30px]'>
            <Typography
              variant='body1'
              color='black'
              className='font-normal text-lg'
            >
              Document History
            </Typography>
            <Typography
              variant='body1'
              color='grey'
              className='font-normal text-base'
            >
              Version: {attestation?.totalVersions + 1}
            </Typography>
          </div>

          {attestation.attestations?.map(at => (
            <DocumentHistory
              key={at.UID}
              documentData={at}
              isLatest={at.version + 1 === attestation?.attestations?.length!}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default OtherUserDoc;
