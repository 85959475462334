import React, { FC, useState } from 'react';

import CompareAttestation from '../../dialogs/CompareAttestation/CompareAttestation';
import Typography from '../../components/Typography/Typography';
import Button from '../../components/Button/Button';
import ASSETS from '../../assets';
import Chip from '../../components/Chip/Chip';
import { useNavigate } from 'react-router-dom';
import { shortenAddress } from '../../utils';
import DocPreview from '../../components/DocPreview/DocPreview';
import { IAttessation } from '../../types/interfaces';
import dayjs from 'dayjs';
import DateSelector from '../../components/DateSelector/DateSelector';
import CopyTextIcon from '../../components/CopyTextIcon/CopyTextIcon';

interface IProps {
  attestation: IAttessation;
  attestations?: IAttessation[];
  latesUID?: string;
}

const DocumentDetails: FC<IProps> = ({
  attestations,
  attestation,
  latesUID,
}) => {
  const [open, setOpen] = useState(false);

  const handleCompare = () => {
    setOpen(true);
  };

  // const { selectedAttestation: attestation2 } = useSelector(
  //   (state: RootState) => state.attesation
  // );
  const [selectedDate, setSelectedDate] = useState(
    dayjs.unix(+attestation.time).format('MMM DD,YYYY @ hh:mm A')
  );
  const [currentAttestation, setCurrentAttestation] =
    useState<IAttessation>(attestation);
  const [isExpanded] = useState(false);

  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/inspect-doc/${currentAttestation.UID}`, {
      state: { currentAttestation, attestations, isOtherUser: true },
    });
  };

  return (
    <div className='flex justify-between gap-8'>
      <div className='relative size-[200px] min-w-[200px] h-[272px] rounded-lg border border-k-border p-0 pb-3 bg-k-grey-200 overflow-hidden'>
        <div className='absolute right-0 cursor-pointer' onClick={handleClick}>
          <img
            src={ASSETS.view}
            alt='Icon'
            className='w-[35px] h-[35px] rounded-tr-lg'
          />
        </div>
        {currentAttestation && (
          <DocPreview
            url={currentAttestation?.document || ''}
            docType={
              currentAttestation?.documentType === 'pdf' ? 'pdf' : 'word'
            }
            className='p-3 bg-transparent'
          />
        )}
        <div className='absolute bottom-0 w-full bg-white p-3 flex flex-col items-center justify-center'>
          <div className='absolute -top-[20px]'>
            <div className='w-[35px] h-[35px] flex items-center justify-center rounded-full bg-k-sky-blue-light text-black text-base semibold border-2 uppercase border-k-sky-blue'>
              {currentAttestation?.email?.slice(0, 2)}
            </div>
          </div>
          <div className='flex flex-col items-center gap-[1px] mt-2'>
            <Typography
              variant='body1'
              color='black'
              className='semibold leading-[17px]'
            >
              {currentAttestation?.email}
            </Typography>
            <div className='flex flex-row gap-[5px]'>
              <Typography variant='body1' color='grey' className='font-medium'>
                {shortenAddress(currentAttestation?.creator || '', 6, 6)}
              </Typography>
              <img src={ASSETS.copy_grey} alt='Verify' />
            </div>
          </div>
        </div>
      </div>
      <div
        className={`relative size-[392px] min-w-[392px] flex flex-col ${
          isExpanded ? 'gap-2' : 'gap-[15px]'
        }`}
      >
        <Typography
          variant='h3'
          color='black'
          className='font-normal leading-[40px]'
        >
          {currentAttestation?.name}
        </Typography>
        <div>
          <Typography variant='h6' color='black' className='font-medium'>
            {currentAttestation?.description}
            {/* {isExpanded
              ? text
              : lines.slice(0, 5).join('\n') + (lines.length > 6 ? '...' : '')} */}
          </Typography>
          {/* {lines.length > 6 && (
            <div className='flex flex-row gap-1 pb-2'>
              <button
                onClick={handleToggle}
                className='text-k-dark-blue text-sm'
              >
                {isExpanded ? 'Read Less' : 'Read More'}
              </button>
              {!isExpanded && <img src={ASSETS.plus_blue} alt='Verify' />}
            </div>
          )} */}
        </div>
        {currentAttestation?.tags && currentAttestation?.tags.length > 0 && (
          <div
            className={`flex items-center gap-2 ${
              isExpanded ? 'mt-[0px]' : 'mt-[18px]'
            }  flex-wrap`}
          >
            {currentAttestation?.tags?.map(tag => (
              <Chip label={tag} key={tag} />
            ))}
          </div>
        )}
        <div className='mt-auto'>
          <Button
            leftIcon={ASSETS.upload}
            label='Verify Document'
            variant='primary'
            className='w-full mt-3'
            onClick={handleCompare}
          />
        </div>
      </div>
      <div className='flex flex-col gap-4'>
        <div>
          <DateSelector
            latestUID={latesUID}
            attestations={attestations}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            setCurrentAttestation={setCurrentAttestation}
          />
        </div>
        <div>
          <Typography variant='body1' color='grey' className='font-medium'>
            Original Date Created:
          </Typography>
          <div className='flex flex-row gap-[5px]'>
            <Typography
              variant='body1'
              color='black'
              className='semibold break-all'
            >
              {currentAttestation &&
                dayjs
                  .unix(+currentAttestation.time)
                  .format('MMM DD,YYYY @ hh:mm A')}
            </Typography>
          </div>
        </div>
        <div>
          <Typography variant='body1' color='grey' className='font-medium'>
            UID:
          </Typography>
          <div className='flex flex-row gap-[5px]'>
            <Typography
              variant='body1'
              color='black'
              className='semibold break-all'
            >
              {shortenAddress(currentAttestation?.UID || '', 10, 10)}
            </Typography>
            <CopyTextIcon textToCopy={currentAttestation?.UID || ''}>
              <img src={ASSETS.copy_grey} alt='Verify' />
            </CopyTextIcon>
          </div>
        </div>
        <div>
          <Typography variant='body1' color='grey' className='font-medium'>
            Doc Hash:
          </Typography>
          <div className='flex flex-row gap-[5px]'>
            <Typography
              variant='body1'
              color='black'
              className='semibold break-all'
            >
              {shortenAddress(currentAttestation?.docHash || '', 10, 10)}
            </Typography>
            <CopyTextIcon textToCopy={currentAttestation?.docHash || ''}>
              <img src={ASSETS.copy_grey} alt='Verify' />
            </CopyTextIcon>
          </div>
        </div>
        <CopyTextIcon
          textToCopy={`https://kpmg.kresus.com/compare-doc/${currentAttestation.UID}`}
        >
          <Button
            leftIcon={ASSETS.copy_black}
            label='Copy Verification Link'
            variant='outlined'
            className='w-full mt-2 '
          />
        </CopyTextIcon>

        <a
          href={currentAttestation?.verifyOnEAS}
          rel='noopener noreferrer'
          target='_blank'
          className='flex items-center justify-center gap-1'
        >
          <Typography variant='body1' color='blue' className='semibold'>
            Verify on EAS
          </Typography>
          <img src={ASSETS.arrow_up_blue} alt='Verify' />
        </a>
      </div>
      {open && currentAttestation && (
        <CompareAttestation
          open={open}
          setOpen={setOpen}
          docHash={currentAttestation?.docHash}
        />
      )}
    </div>
  );
};

export default DocumentDetails;
