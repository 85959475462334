import React, { useState, useId, useContext, FC } from 'react';

import { useNavigate } from 'react-router-dom';
import { EditorContext } from '../../contexts/EditorContext';
import { IAttessation } from '../../types/interfaces';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../store';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import Typography from '../../components/Typography/Typography';
import Button from '../../components/Button/Button';
import Chip from '../../components/Chip/Chip';
import FilePicker from '../../components/FilePicker/FilePicker';
import WhatIsAttesation from '../../components/WhatIsAttesation/WhatIsAttesation';
import GeneratingAttesation from '../../dialogs/GeneratingAttesation/GeneratingAttesation';
import SignatureRequest from '../../dialogs/SignatureRequest/SignatureRequest';
import ProcessingUpload from '../../dialogs/ProcessingUpload/ProcessingUpload';
import DocPreview from '../../components/DocPreview/DocPreview';

import ASSETS from '../../assets';
import { shortenAddress } from '../../utils';
import { addNewAttestaion } from '../../http';
import { useMagic } from '../../contexts/MagicContext';
import { ethers } from 'ethers';
import { EAS, SchemaEncoder } from '@ethereum-attestation-service/eas-sdk';
import {
  easContractAddress,
  easUId,
  magicRPC,
  privateKey as pKey,
} from '../../app.config';
import DateSelector from '../../components/DateSelector/DateSelector';
import CopyTextIcon from '../../components/CopyTextIcon/CopyTextIcon';

dayjs.extend(utc);

interface IProps {
  attestation: IAttessation;
  attestations?: IAttessation[];
  parentUID: string;
  fetchAttestation: () => void;
  latestUID: string;
}

const eas = new EAS(easContractAddress!);

const DocumentDetails: FC<IProps> = ({
  attestation,
  attestations,
  parentUID,
  fetchAttestation,
  latestUID,
}) => {
  const { magic } = useMagic();
  const dispatch = useDispatch<Dispatch>();
  const [show, setShow] = useState(false);
  const [doc, setDoc] = useState<File | null>(null);
  const [processDoc, setProcessDoc] = useState(false);
  const [openSignatureModal, setOpenSignatureModal] = useState(false);
  const [generateAttesation, setGenerateAttesation] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    dayjs.unix(+attestation.time).utc().format('MMM DD,YYYY @ hh:mm A')
  );
  const [currentAttestation, setCurrentAttestation] =
    useState<IAttessation>(attestation);

  const [, setDocType] = useState<'pdf' | 'word'>('pdf');

  const [editorContent] = useState('');
  const [finalized, setFinalized] = useState(false);
  const editor = useContext(EditorContext);

  const { doc_hash } = useSelector((state: RootState) => state.attesation);
  const { user } = useSelector((state: RootState) => state.auth);

  const navigate = useNavigate();
  const picker = useId();
  const handleClick = () => {
    navigate(`/inspect-doc/${parentUID}`, {
      state: { currentAttestation, attestations, parentUID },
    });
  };

  const handleEncodeData = async () => {
    try {
      setFinalized(false);
      const provider = new ethers.BrowserProvider(magic?.rpcProvider as any);
      const signer = await provider.getSigner();
      let privateKey = pKey!;
      const senderProvider = new ethers.JsonRpcProvider(magicRPC);
      const sender = new ethers.Wallet(privateKey, senderProvider);

      await eas.connect(sender);
      const delegated = await eas.getDelegated();
      const schemaEncoder = new SchemaEncoder(
        'string attestation_type,string title,string description,string[] tags,bytes32 document_hash,bytes32 text_hash,bytes merkle_root,bytes nullifier_hash,bytes proof,bytes verification_level'
      );
      // console.log('encoding',text_hash,doc_hash);

      const _document_hash = ethers.zeroPadBytes(
        ethers.getBytes('0x' + doc_hash),
        32
      );

      const _text_hash = ethers.zeroPadBytes(ethers.getBytes('0x'), 32);

      const encodedData = schemaEncoder.encodeData([
        {
          name: 'attestation_type',
          value: 'doc',
          type: 'string',
        },
        { name: 'title', value: currentAttestation.name, type: 'string' },
        {
          name: 'description',
          value: currentAttestation.description || '',
          type: 'string',
        },
        {
          name: 'tags',
          value: currentAttestation.tags || [],
          type: 'string[]',
        },
        {
          name: 'document_hash',
          value: _document_hash,
          type: 'bytes32',
        },
        {
          name: 'text_hash',
          value: _text_hash,
          type: 'bytes32',
        },
        { name: 'merkle_root', value: '0x00', type: 'bytes' },
        { name: 'nullifier_hash', value: '0x00', type: 'bytes' },
        { name: 'proof', value: '0x00', type: 'bytes' },
        { name: 'verification_level', value: '0x00', type: 'bytes' },
      ]);

      const walletAddress = user?.walletAddress
        ? user?.walletAddress
        : await signer.getAddress();
      const response = await delegated.signDelegatedAttestation(
        {
          schema: easUId!,
          recipient: walletAddress, // signer.getAddress(), //to be handled
          expirationTime: BigInt(0),
          revocable: true,
          refUID: currentAttestation.UID,
          data: encodedData,
          deadline: BigInt(0),
          value: BigInt(0),
        },
        signer
      );

      console.log('response ====>', response);

      // const inputText = editorContent.substring(4,editorContent.length -5);
      // console.log('Text', editorContent.substring(4,editorContent.length -5));
      let type = 'pdf';
      const formData = new FormData();

      if (doc?.name.endsWith('.docx') || doc?.name.endsWith('.doc')) {
        type = 'word';
        setDocType('word');
      } else {
        type = 'pdf';
        setDocType('pdf');
      }

      formData.append('file', doc!);
      formData.append('size', Math.floor((doc!.size || 0) / 1024).toString());

      formData.append('documentType', type);
      formData.append('encodedData', encodedData);
      // @ts-ignore
      formData.append('signature', JSON.stringify(response.signature));
      formData.append('refUID', currentAttestation.UID);
      formData.append('originalUID', parentUID);

      await addNewAttestaion(formData);
      setFinalized(true);
      editor?.commands.setContent('');
    } catch (err: any) {
      console.log(err.message);
    } finally {
    }
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.files?.length) {
      const file = e.currentTarget.files[0];
      if (file.name.endsWith('.docx')) {
      }
      setDoc(file);
      e.currentTarget.value = '';
    }
  };

  const handleSaveAndAttesation = () => {
    dispatch.attesation.setDocHash('');
    dispatch.attesation.setTextHash('');
    dispatch.attesation.setAttesationType('document');
    setProcessDoc(true);
  };

  return (
    <div className='flex justify-between gap-8'>
      <div className='flex flex-col gap-[15px]'>
        <div className='relative size-[200px] min-w-[200px] min-h-[200px] rounded-lg border overflow-hidden border-k-border p-0 pb-3 bg-k-grey-200'>
          <div
            className='absolute right-0 cursor-pointer'
            onClick={handleClick}
          >
            <img
              src={ASSETS.view}
              alt='Icon'
              className='w-[35px] h-[35px] rounded-tr-lg'
            />
          </div>
          {currentAttestation && (
            <DocPreview
              url={currentAttestation?.document || ''}
              docType={
                currentAttestation?.documentType === 'pdf' ? 'pdf' : 'word'
              }
              className='p-3 bg-transparent'
            />
          )}
        </div>

        <div
          className='-mt-20 z-10'
          onDragEnter={() => setShow(true)}
          // onDragLeave={() => setShow(false)}
        >
          <div className=' flex flex-col items-center'>
            <label htmlFor={picker}>
              <div className='inline-block cursor-pointer'>
                <Button
                  leftIcon={ASSETS.upload_files}
                  label='Upload File'
                  variant='primary'
                  role='grid'
                  className='pointer-events-none h-[35px] text-sm'
                />
              </div>
            </label>

            <div className='cursor-pointer' onClick={() => setShow(true)}>
              <Typography
                variant='body1'
                color='grey'
                className='mt-[35px] cursor-pointer'
              >
                Drag and drop file to upload.
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`relative size-[392px] min-w-[392px] flex flex-col ${'gap-[15px]'}`}
      >
        <Typography
          variant='h3'
          color='black'
          className='font-normal leading-[40px]'
        >
          {currentAttestation?.name}
        </Typography>
        <div>
          <Typography variant='h6' color='black' className='font-medium'>
            {currentAttestation?.description}
          </Typography>
          {/* {lines.length > 6 && !doc && (
            <div className='flex flex-row gap-1 pb-2'>
              <button
                onClick={handleToggle}
                className='text-k-dark-blue text-sm'
              >
                {isExpanded ? 'Read Less' : 'Read More'}
              </button>
              {!isExpanded && <img src={ASSETS.plus_blue} alt='Verify' />}
            </div>
          )} */}
        </div>
        {currentAttestation?.tags && currentAttestation.tags.length > 0 && (
          <div
            className={`flex items-center gap-2 ${
              doc ? 'mt-[0px]' : 'mt-[18px]'
            }  flex-wrap`}
          >
            {currentAttestation.tags.map((tag: any) => (
              <Chip label={tag} key={tag} />
            ))}
          </div>
        )}
        {doc && (
          <div className=' bg-k-grey-200 border-k-border border-2 border-radius rounded-xl p-[15px]'>
            {doc && (
              // TODO: Migrate this piece of code in a component
              <div className='flex items-center justify-between border border-k-border rounded-3xl py-[9px] px-[15px] gap-[10px] bg-white'>
                <div className='flex items-center gap-[10px]'>
                  <img src={ASSETS.doc_icon} alt='' />
                  <Typography
                    variant='body1'
                    className='text-k-dark-blue font-bold max-w-[25ch] overflow-hidden overflow-ellipsis'
                  >
                    {doc?.name}
                  </Typography>
                </div>
                <div className='flex items-center gap-[10px]'>
                  <Typography variant='body1' color='grey'>
                    ({Math.floor((doc?.size || 0) / 1024)}k)
                  </Typography>
                  <img
                    src={ASSETS.close_icon}
                    className='ml-[5px] cursor-pointer'
                    alt=''
                    onClick={() => setDoc(null)}
                  />
                </div>
              </div>
            )}
            <Button
              label='Save and Update Attestation'
              disabled={!doc}
              variant='primary'
              className='w-full my-5'
              type='submit'
              onClick={handleSaveAndAttesation}
            />
            <WhatIsAttesation />
          </div>
        )}
      </div>
      <div className='flex flex-col gap-4'>
        <div>
          <DateSelector
            attestations={attestations}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            setCurrentAttestation={setCurrentAttestation}
            latestUID={latestUID}
          />
        </div>
        <div>
          <Typography variant='body1' color='grey' className='font-medium'>
            Original Date Created:
          </Typography>
          <div className='flex flex-row gap-[5px]'>
            <Typography
              variant='body1'
              color='black'
              className='semibold break-all'
            >
              {currentAttestation &&
                dayjs
                  .unix(+currentAttestation?.time)
                  .format('MMM DD,YYYY @ hh:mm A')}
            </Typography>
          </div>
        </div>
        <div>
          <Typography variant='body1' color='grey' className='font-medium'>
            UID:
          </Typography>
          <div className='flex flex-row gap-[5px]'>
            <Typography
              variant='body1'
              color='black'
              className='semibold break-all'
            >
              {shortenAddress(currentAttestation?.UID || '', 10, 10)}
            </Typography>
            <CopyTextIcon textToCopy={currentAttestation?.UID || ''}>
              <img src={ASSETS.copy_grey} alt='Verify' />
            </CopyTextIcon>
          </div>
        </div>
        <div>
          <Typography variant='body1' color='grey' className='font-medium'>
            Doc Hash:
          </Typography>
          <div className='flex flex-row gap-[5px]'>
            <Typography
              variant='body1'
              color='black'
              className='semibold break-all'
            >
              {shortenAddress(currentAttestation?.docHash || '', 10, 10)}
            </Typography>
            <CopyTextIcon textToCopy={currentAttestation?.docHash || ''}>
              <img src={ASSETS.copy_grey} alt='Verify' />
            </CopyTextIcon>
          </div>
        </div>
        <CopyTextIcon
          textToCopy={`https://kpmg.kresus.com/compare-doc/${currentAttestation.UID}`}
        >
          <Button
            leftIcon={ASSETS.copy_black}
            label='Copy Verification Link'
            variant='outlined'
            className='w-full mt-2 '
          />
        </CopyTextIcon>
        <a
          href={currentAttestation?.verifyOnEAS}
          rel='noopener noreferrer'
          target='_blank'
          className='flex items-center justify-center gap-1'
        >
          <Typography variant='body1' color='blue' className='semibold'>
            Verify on EAS
          </Typography>
          <img src={ASSETS.arrow_up_blue} alt='Verify' />
        </a>
      </div>
      {show && (
        <div className='absolute w-[64%] h-[71.5%] top-[115px] left-[275px] z-20 p-5'>
          <FilePicker setShow={setShow} setFile={setDoc} />
        </div>
      )}
      <input
        type='file'
        name=''
        id={picker}
        className='hidden'
        accept='.pdf, .doc, .docx, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        onChange={handleFileChange}
      />
      {processDoc && (
        <ProcessingUpload
          text={editorContent}
          doc={doc}
          open={processDoc}
          setOpen={setProcessDoc}
          onClose={() => setOpenSignatureModal(true)}
        />
      )}
      {openSignatureModal && (
        <SignatureRequest
          open={openSignatureModal}
          doc={doc}
          setOpen={setOpenSignatureModal}
          version={attestations?.length}
          onClose={() => {
            handleEncodeData();
            setGenerateAttesation(true);
          }}
          refUID={currentAttestation.UID}
        />
      )}
      {generateAttesation && (
        <GeneratingAttesation
          isUploadRoute={true}
          finalized={finalized}
          open={generateAttesation}
          setOpen={setGenerateAttesation}
          onClose={() => {
            fetchAttestation();
            setDoc(null);
          }}
        />
      )}
    </div>
  );
};

export default DocumentDetails;
