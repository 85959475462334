import React, { useEffect, useState } from 'react';
import { IAttessation } from '../../types/interfaces';
import { useDispatch } from 'react-redux';
import { Dispatch } from '../../store';
import dayjs from 'dayjs';
import Typography from '../../components/Typography/Typography';
import { twMerge } from 'tailwind-merge';
import ASSETS from '../../assets';
import ClickAwayListener from 'react-click-away-listener';

interface DateSelectorProps {
  attestations?: IAttessation[];
  selectedDate: string;
  setSelectedDate: (date: string) => void;
  setCurrentAttestation: (attestation: IAttessation) => void;
  customWidth?: string;
  latestUID?: string;
}

const DateSelector: React.FC<DateSelectorProps> = ({
  attestations,
  selectedDate,
  setSelectedDate,
  setCurrentAttestation,
  customWidth = '200px',
  latestUID,
}) => {
  const dispatch = useDispatch<Dispatch>();

  const [isOpen, setIsOpen] = useState(false);

  const [current, setCurrent] = useState<IAttessation | null>(null);
  const [isLatest, setIsLates] = useState(false);

  const handleDateSelect = (uid: string) => {
    setSelectedDate(uid);
    setIsOpen(false);
    const selectedAttestation = attestations?.find(att => att.UID === uid);
    if (selectedAttestation) {
      setCurrentAttestation(selectedAttestation);
    }
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // useEffect(() => {
  //   if (times && times.length) {
  //     setSelectedDate(times[0] || '');
  //   }
  // }, [times]);

  useEffect(() => {
    if (attestations) {
      const att = attestations.find(a => a.UID === selectedDate)!;
      dispatch.attesation.setSelectedAttestation(att);
      setCurrentAttestation(att);
      setCurrent(att);
    }
    if (selectedDate === latestUID) {
      setIsLates(true);
    } else {
      if (latestUID === '') {
        setIsLates(true);
      } else {
        setIsLates(false);
      }
    }
  }, [selectedDate, latestUID]);

  useEffect(() => {
    if (attestations?.length) {
      setCurrentAttestation(attestations[0]);
      setCurrent(attestations[0]);
      setSelectedDate(attestations[0].UID);
      dispatch.attesation.setSelectedAttestation(attestations[0]);
    }
  }, [attestations]);

  return (
    <div className={twMerge('flex flex-col gap-1', isOpen && 'mb-[37.5px]')}>
      {!isLatest && (
        <div>
          <Typography variant='body1' color='grey' className='semibold'>
            Viewing Version
          </Typography>
        </div>
      )}
      <div className='relative' style={{ maxWidth: customWidth }}>
        {' '}
        <div
          className={twMerge(
            `max-w-[${customWidth}] h-full overflow-hidden`,
            !isOpen && 'border rounded-lg'
          )}
        >
          {isLatest && (
            <div className='w-full h-7 bg-black flex items-center rounded-t-lg pl-3 gap-2'>
              <img src={ASSETS.tick_small} alt='Verify' />
              <Typography variant='body1' color='white' className='semibold'>
                Latest Version
              </Typography>
            </div>
          )}
          {!isOpen && (
            <div
              className='w-full h-9 bg-white flex items-center px-4 justify-between cursor-pointer'
              onClick={toggleDropdown}
            >
              <Typography variant='body1' color='black' className='semibold'>
                {current?.time &&
                  dayjs.unix(+current.time).format('MMM DD,YYYY @ hh:mm A')}
              </Typography>
              <img src={ASSETS.arrow_down} alt='Arrow down' />
            </div>
          )}
        </div>
        {isOpen && (
          <ClickAwayListener onClickAway={() => setIsOpen(false)}>
            <div
              className={twMerge(
                `absolute z-10 w-full max-w-[${customWidth}] bg-white border shadow-lg`
                // isSelectedDateLatest ? 'rounded-b-lg' : 'rounded-lg'
              )}
            >
              {attestations?.map((attestation: IAttessation, index: any) => (
                <div
                  key={index}
                  className='w-full h-9 px-4 flex items-center justify-between cursor-pointer hover:bg-gray-200'
                  onClick={() => handleDateSelect(attestation.UID)}
                >
                  <Typography
                    variant='body1'
                    color='black'
                    className='semibold'
                  >
                    {attestation?.time &&
                      dayjs
                        .unix(+attestation.time)
                        .format('MMM DD,YYYY @ hh:mm A')}
                  </Typography>
                </div>
              ))}
            </div>
          </ClickAwayListener>
        )}
      </div>
    </div>
  );
};

export default DateSelector;
