import React, {
  ChangeEvent,
  FC,
  InputHTMLAttributes,
  useId,
  useState,
} from 'react';

import Chip from '../Chip/Chip';
import { twMerge } from 'tailwind-merge';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  showButton?: boolean;
  tags?: string[];
  onTagChange?: (v: string) => void;
}

const TagsField: FC<IProps> = ({
  value,
  label,
  tags = [],
  onTagChange,
  ...rest
}) => {
  const id = useId();

  const [val, setVal] = useState('');

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setVal(e.currentTarget.value);
  };

  const addTags = (input: string) => {
    const MAX_TAG_LENGTH = 30;
    const newTags = input
      .split(',')
      .map(tag => tag.trim())
      .filter(
        tag =>
          tag.length > 0 &&
          tag.length <= MAX_TAG_LENGTH &&
          !tags.includes(tag) &&
          tags.length < 5
      ); // Check for duplicates and limit

    newTags.forEach(tag => {
      if (typeof onTagChange === 'function') {
        onTagChange(tag);
      }
    });
  };

  return (
    <div className='w-full max-w-[488px]  min-h-[65px] border border-k-input-border flex flex-col p-3 rounded-[12px] relative group focus-within:border-k-black focus-within:shadow-border'>
      {!!tags?.length && (
        <div className='flex items-center gap-[5px] mt-5 flex-wrap'>
          {tags?.map(tag => (
            <Chip
              label={tag}
              onDelete={() => {
                onTagChange && onTagChange(tag);
              }}
            />
          ))}
        </div>
      )}
      <input
        className='w-full peer border border-k-black outline-none border-none text-lg mt-auto placeholder:text-transparent'
        placeholder='hi'
        value={val}
        onChange={handleChange}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            e.preventDefault();
            if (val.trim()) {
              addTags(val);
              setVal('');
            }
          }
        }}
        onBlur={() => {
          if (val.trim()) {
            addTags(val);
            setVal('');
          }
        }}
        {...rest}
        id={id}
      />
      <p
        className={twMerge(
          'text-lg text-k-grey-text absolute top-[17px]  peer-[:focus]:text-sm peer-[:focus]:top-[6px] peer-[:not(:placeholder-shown)]:text-sm  peer-[:not(:placeholder-shown)]:top-[6px] transition-[top] duration-200 ease pointer-events-none',
          tags?.length && 'top-2 text-sm'
        )}
      >
        {label}
      </p>
    </div>
  );
};

export default TagsField;
