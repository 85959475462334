import React, { FC } from 'react';
import ASSETS from '../../assets';
import { twMerge } from 'tailwind-merge';

interface IProps {
  label: string;
  onDelete?: () => void;
  isDuplicate?: boolean;
}

const Chip: FC<IProps> = ({ label, onDelete, isDuplicate }) => {
  return (
    <div className={twMerge('px-[10px] py-[5.5px] border border-k-border rounded-3xl text-xs font-semibold z-10 flex items-center gap-2', isDuplicate ? 'border-red-500 text-red-500' : '' )}>
      <span className='text-inherit'>{label}</span>
      {onDelete && (
        <img
          src={ASSETS.x_grey}
          alt=''
          className='cursor-pointer'
          onClick={() => onDelete()}
        />
      )}
    </div>
  );
};

export default Chip;
