import React, { FC, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { RootState, Dispatch } from "../../../store";
import { useNavigate } from "react-router-dom";

import Typography from "../../../components/Typography/Typography";
import Button from "../../../components/Button/Button";
import WhatIsAttesation from "../../../components/WhatIsAttesation/WhatIsAttesation";
import ASSETS from "../../../assets";
import Attestation from "./Attestation";
import { IAttessation } from "../../../types/interfaces";
import { ClipLoader } from "react-spinners";
interface IProps {
  searchLoading: boolean;
}
const MyAttesations: FC<IProps> = ({searchLoading}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();

  const { myAttestations, searchedAttestation, loading } = useSelector(
    (state: RootState) => state.attesation
  );

  const matchedAttestations = searchedAttestation?.attestationData.filter(
    (searchedObj: { UID: string }) =>
      myAttestations?.some((myObj) => myObj.UID === searchedObj.UID)
  );

  const handleAddNewAttestation = () => {
    navigate("/add-document");
  };
  useEffect(() => {
    dispatch.attesation.handleGetMyAttestations();
    // eslint-disable-next-line
  }, []);
  const finalAttestations = matchedAttestations || myAttestations;

  return (
    <>
      {searchLoading && (
        <div className="flex-grow h-1 min-h-[300px]">
          <div className="h-full bg-k-grey-bg border border-k-border rounded-[12px] flex flex-col items-center justify-center">
            <ClipLoader size={30} color="#030A74" />
          </div>
        </div>
      )}
      {finalAttestations.length > 0 && !searchLoading ? (
        <div className="flex flex-col">
          {finalAttestations?.map((attesation: IAttessation) => (
            <Attestation attestation={attesation} key={attesation.UID} />
          ))}
        </div>
      ) : (
        !searchLoading && (
          <div className="flex-grow h-1 min-h-[300px]">
            <div className="h-full bg-k-grey-bg border border-k-border rounded-[12px] flex flex-col items-center justify-center">
              <Typography variant="h4">Nothing to show currently.</Typography>
              <Typography variant="h4">
                Please add an attestation to get started.
              </Typography>
              <Button
                label="Add New Attestation"
                leftIcon={ASSETS.add_black}
                className="my-[25px]"
                onClick={handleAddNewAttestation}
              />
              <div className="flex items-center gap-2 cursor-pointer">
                <WhatIsAttesation />
              </div>
            </div>
          </div>
        )
      )}
    </>
  );
};

export default MyAttesations;
