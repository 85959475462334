import React from 'react';
import Typography from '../../components/Typography/Typography';
import { ClipLoader } from 'react-spinners';

const Logging = () => {
  return (
    <div className='flex flex-col items-center justify-center flex-grow'>
      <div className="flex items-center justify-center my-5">
          <ClipLoader size={30} color="#030A74" />
        </div>
      <Typography variant='h3' className='mt-[10px] title'>
        Securely logging you in
      </Typography>
      <Typography
        variant='h5'
        color='grey'
        className='max-w-[35ch] text-center mt-[15px]'
      >
        If this is your first-time here, it may take a few moments as we deploy
        your account.
      </Typography>
    </div>
  );
};

export default Logging;
