import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import Typography from '../../components/Typography/Typography';
import Button from '../../components/Button/Button';
import { useDispatch } from 'react-redux';
import { Dispatch } from '../../store';

import ASSETS from '../../assets';
import CompareDoc from './CompareDoc';
import { useParams } from 'react-router-dom';
import { IAttessation } from '../../types/interfaces';
import { getAttestationViaUID } from '../../http';

const Comparison = () => {
  const id: string = useParams()?.id || '';
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();

  const [attestation, setAttestation] = useState<IAttessation | null>(null);
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);

  const handleFetchAttestation = async () => {
    try {
      setLoading(true);
      const { data } = await getAttestationViaUID(id);
      dispatch.attesation.setSelectedAttestation(data.attestationData);
      setAttestation(data.attestationData);
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      handleFetchAttestation();
    }
    // eslint-disable-next-line
  }, [id]);

  return (
    <div className='min-h-screen bg-white flex flex-col'>
      <div className='h-[168px]  bg-g_gradient'>
        <div className='h-full  pb-[51px] flex items-center justify-center gap-5 bg-f_gradient'>
          <div className='w-full max-w-[980px] px-5 flex items-center justify-between'>
            <div className='flex items-center gap-5'>
              <img src={ASSETS.kpmg_logo} alt='' />
              <Typography variant='h3' color='white'>
                +
              </Typography>
              <img src={ASSETS.kresus_logo} alt='' />
            </div>
            <div className='flex items-center gap-[25px]'>
              <div className='flex items-center gap-[25px]'>
                <Typography variant='h6' color='white'>
                  Welcome
                </Typography>
              </div>
              <Button
                label='Log In'
                textColor='white'
                leftIcon={ASSETS.settings}
                onClick={() => navigate('/')}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='flex-grow flex flex-col w-full bg-white '>
        <div className='flex-grow w-full max-w-[980px] mx-auto mt-[-51px] flex flex-col'>
          {attestation && <CompareDoc docHash={attestation.docHash} />}
        </div>
      </div>
    </div>
  );
};

export default Comparison;
