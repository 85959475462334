import React, { FC, useState } from 'react';

import Typography from '../../components/Typography/Typography';
import Button from '../../components/Button/Button';
import ASSETS from '../../assets';
import InfoItem from './InfoItem';
import { IAttessation, IUser } from '../../types/interfaces';
import DocPreview from '../../components/DocPreview/DocPreview';
import DateSelector from '../../components/DateSelector/DateSelector';
import dayjs from 'dayjs';
import CompareAttestation from '../../dialogs/CompareAttestation/CompareAttestation';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

interface IProps {
  attestaion: IAttessation;
  allAttestations?: IAttessation[];
  isMyAttestation?: boolean;
  isOtherUser?: boolean;
  user: IUser | null;
  latestUID: string;
}

const InspectDocDetails: FC<IProps> = ({
  attestaion,
  allAttestations,
  isOtherUser,
  latestUID,
}) => {
  const [selectedDate, setSelectedDate] = useState(
    dayjs.unix(+attestaion.time).utc().format('MMM DD,YYYY @ hh:mm A')
  );
  const [currentAttestation, setCurrentAttestation] =
    useState<IAttessation>(attestaion);
  const { user } = useSelector((state: RootState) => state.auth);
  const [open, setOpen] = useState(false);
  const handleCompare = () => {
    setOpen(true);
  };

  const handleOpenFile = (hideToolbar = false) => {
    const fileUrl: any = currentAttestation.document;
    const finalUrl = hideToolbar ? fileUrl : `${fileUrl}#toolbar=0`;
    window.open(finalUrl, '_blank');
  };
  return (
    <div className='flex justify-between gap-12 flex-grow'>
      <div className='relative w-[502px] min-w-[300px]  rounded-lg border border-k-border bg-k-grey-200'>
        {currentAttestation.document && (
          <DocPreview
            url={currentAttestation.document}
            docType={currentAttestation.documentType === 'pdf' ? 'pdf' : 'word'}
          />
        )}
      </div>

      <div className='flex flex-col gap-4 min-w-[328px]'>
        <Typography variant='h3' color='black' className='font-normal'>
          {currentAttestation.name}
        </Typography>
        {isOtherUser && (
          <div className='flex flex-row gap-[10px]'>
            <div className='w-[35px] h-[35px] flex items-center justify-center rounded-full bg-k-sky-blue-light text-black text-base semibold border-2 uppercase border-k-sky-blue'>
              {currentAttestation?.email?.slice(0, 2)}
            </div>
            <div className='flex flex-col'>
              <Typography variant='body1' color='black' className='semibold'>
                {currentAttestation.email}
              </Typography>
              <Typography variant='body1' color='grey' className='font-medium'>
                Owner
              </Typography>
            </div>
          </div>
        )}

        <div>
          <DateSelector
            attestations={allAttestations}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            setCurrentAttestation={setCurrentAttestation}
            customWidth='100%'
            latestUID={latestUID}
          />
        </div>

        <div className='flex gap-4'>
          <div className='flex flex-col gap-4'>
            <div>
              <Typography variant='body1' color='grey' className='font-medium'>
                Original Date Created:
              </Typography>
              <Typography
                variant='body1'
                color='black'
                className='semibold break-all'
              >
                {dayjs
                  .unix(+currentAttestation.time)
                  .utc()
                  .format('MMM DD,YYYY @ hh:mm A')}
              </Typography>
            </div>
            <InfoItem
              label='UID:'
              value={currentAttestation.UID}
              icon={ASSETS.copy_grey}
            />
            <InfoItem
              label='File Name:'
              value={currentAttestation.document || ''}
            />
          </div>

          <div className='flex flex-col gap-4'>
            <InfoItem
              label='Your Wallet Address:'
              value={currentAttestation.creator}
              icon={ASSETS.copy_grey}
            />
            <InfoItem
              label='Doc Hash:'
              value={currentAttestation.docHash}
              icon={ASSETS.copy_grey}
            />
          </div>
        </div>
        <div className='flex flex-col gap-4 mt-7'>
          {user?.walletAddress !== currentAttestation.creator && (
            <Button
              leftIcon={ASSETS.upload}
              label='Verify Document'
              variant='primary'
              className='w-full mt-3'
              onClick={handleCompare}
            />
          )}
          <Button
            rightIcon={ASSETS.arrow_up}
            label='Open File In Window'
            variant='outlined'
            className='w-full h-[35px] text-sm'
            onClick={() => handleOpenFile(false)}
          />
          <Button
            leftIcon={ASSETS.copy_black}
            label='Print File'
            variant='outlined'
            className='w-full h-[35px] text-sm'
            onClick={() => handleOpenFile(true)}
          />
        </div>
      </div>
      {open && currentAttestation && (
        <CompareAttestation
          open={open}
          setOpen={setOpen}
          docHash={currentAttestation?.docHash}
        />
      )}
    </div>
  );
};

export default InspectDocDetails;
