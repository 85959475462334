import React, { FC } from 'react';

import DialogLayout from '../DialogLayout';
import Typography from '../../components/Typography/Typography';
import ASSETS from '../../assets';
import './custom.css';

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const AttesationInfo: FC<IProps> = ({ open, setOpen }) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <DialogLayout open={open}>
      <div className='w-full max-w-[815px] bg-white p-[50px] rounded-[15px] relative'>
        <div
          className='absolute top-[25px] right-[25px] rounded-full size-[30px] bg-[#0000000D] cursor-pointer grid place-items-center'
          onClick={handleClose}
        >
          <img src={ASSETS.cross} alt='' />
        </div>
        <div className='max-h-[70vh] overflow-y-auto hide-scrollbar' >
        <Typography variant='h2' className='font-bold title'>
          What is Attestation?
        </Typography>
        <Typography variant='h5' className='mt-[25px]'>
          Attestation refers to the process of validating or certifying that a
          document is authentic, accurate, and has not been tampered with. When
          a document is attested on the blockchain, a cryptographic proof
          (usually a hash of the document) is created and stored on the
          blockchain. This proof serves as a digital fingerprint of the
          document.
        </Typography>
        <Typography variant='h5' className='my-[25px]'>
          Here’s how it typically works:
        </Typography>
        <Typography variant='h5' className='font-bold'>
          Hashing
        </Typography>
        <Typography variant='h5'>
          The document is hashed, which converts it into a unique string of
          characters. Even a small change in the document will result in a
          completely different hash.
        </Typography>
        <Typography variant='h5' className='font-bold mt-[25px]'>
          Storing on Blockchain
        </Typography>
        <Typography variant='h5'>
          The hash, along with any other relevant metadata (like timestamps), is
          recorded on the blockchain. This entry serves as an immutable record
          of the document’s state at that point in time.
        </Typography>
        <Typography variant='h5' className='font-bold mt-[25px]'>
          Verification
        </Typography>
        <Typography variant='h5'>
          Later, anyone can verify the authenticity of the document by hashing
          the current version and comparing it to the hash stored on the
          blockchain. If the hashes match, it proves that the document is
          unchanged and was indeed attested at the time the blockchain record
          was made.
        </Typography>
        <Typography variant='h5' className='mt-[25px]'>
          This process ensures that the document is both secure and can be
          independently verified without relying on a central authority.
        </Typography>
        </div>
      </div>
    </DialogLayout>
  );
};

export default AttesationInfo;
