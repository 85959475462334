import React, { useEffect, useState } from 'react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';

import Typography from '../../components/Typography/Typography';
import InspectDocDetails from './InspectDocDetails';
import ASSETS from '../../assets';
import { IAttessation, IUser } from '../../types/interfaces';
import { getAttestationByUid } from '../../http';

const InspectDoc = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const id = useParams()?.id as string;

  const attestationFromState: IAttessation | null =
    location?.state?.currentAttestation || null;
  const allAttestations: IAttessation[] = location?.state?.attestations || [];
  const isOtherUser: boolean = location?.state?.isOtherUser || false;

  const [attestation, setAttestation] = useState<IAttessation | null>(
    attestationFromState
  );
  const [attestations, setAttestations] = useState<IAttessation[] | undefined>(
    allAttestations
  );
  const [user, setUser] = useState<IUser | null>(null);
  const [, setLoading] = useState(false);
  const handleClick = () => {
    navigate(-1);
  };
  const handleGetAttestation = async () => {
    try {
      setLoading(true);
      const { data } = await getAttestationByUid(id);
      setAttestation(data.attestationData);
      setAttestations(data?.attestationData?.attestations);
      if (!data.isUserAttestation) {
        setUser(data.userData);
      }
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!attestation && id) {
      handleGetAttestation();
    }
  }, [attestation, id]);

  return (
    <>
      <div className='flex-grow bg-white shadow-auth_box rounded-[15px] p-[50px] pt-[25px] flex flex-col mb-[80px]'>
        <div
          className='flex gap-2 pb-[16px] cursor-pointer'
          onClick={handleClick}
        >
          <img src={ASSETS.arrow_left} alt='' />
          <Typography variant='body1' color='blue' className='semibold text-sm'>
            Document Overview
          </Typography>
        </div>
        {attestation && (
          <InspectDocDetails
            attestaion={attestation}
            allAttestations={attestations}
            user={user}
            isOtherUser={isOtherUser}
            latestUID={attestation.latestAttestation?.UID || ''}
          />
        )}
      </div>
    </>
  );
};

export default InspectDoc;
