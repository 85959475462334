import React, { FC, useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { RootState, Dispatch } from "../../store";

import { object, string } from "yup";
import { useFormik } from "formik";

import DialogLayout from "../DialogLayout";
import Typography from "../../components/Typography/Typography";
import ASSETS from "../../assets";
import TextField from "../../components/TextField/TextField";
import Button from "../../components/Button/Button";
import { updateUser } from "../../http";
import CopyTextIcon from "../../components/CopyTextIcon/CopyTextIcon";

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const schema = object({
  firstName: string()
    .required("First name is required")
    .max(20, "First name cannot exceed 20 characters")
    .matches(
      /^[A-Za-z\s]*$/,
      "First name cannot contain numbers or special characters"
    ),

  lastName: string()
    .required("Last name is required")
    .max(20, "Last name cannot exceed 20 characters")
    .matches(
      /^[A-Za-z\s]*$/,
      "Last name cannot contain numbers or special characters"
    ),
});

const Profile: FC<IProps> = ({ open, setOpen }) => {
  const dispatch = useDispatch<Dispatch>();

  const { user } = useSelector((state: RootState) => state.auth);

  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
    },
    validationSchema: schema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const { data } = await updateUser(values);
        dispatch.auth.setUser(data.user);
        handleClose();
      } catch (err: any) {
        console.log(err.message);
      } finally {
        setLoading(false);
      }
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (user) {
      formik.setValues({
        firstName: user.firstName || "",
        lastName: user.lastName || "",
      });
    }
    // eslint-disable-next-line
  }, [user]);

  return (
    <DialogLayout open={open}>
      <div className="w-full max-w-[536px] p-[50px] bg-white rounded-[15px] relative">
        <div
          className="absolute top-[25px] right-[25px] rounded-full size-[30px] bg-[#0000000D] cursor-pointer grid place-items-center"
          onClick={handleClose}
        >
          <img src={ASSETS.cross} alt="" />
        </div>
        <Typography variant="h2" className="font-bold title">
          Profile
        </Typography>
        <Typography
          variant="body1"
          color="grey"
          className="mt-[5px] font-medium"
        >
          Edit your name and copy your wallet address. To make a change to your
          email address, please contact your organization’s admin.
        </Typography>
        <div className="border border-k-border rounded-lg p-6 mt-[25px] flex flex-col gap-6">
          <div>
            <Typography variant="body1" color="grey">
              Wallet Address
            </Typography>
            <div className="flex items-end gap-[30px] mt-2">
              <div className="flex-grow">
                <Typography className="break-words max-w-[300px]">
                  {user?.walletAddress}
                </Typography>
              </div>
              <div className="flex items-center cursor-pointer">
                <CopyTextIcon textToCopy={user?.walletAddress || ""}>
                  <div className="flex flex-row gap-2">
                    <Typography variant="body1" color="blue">
                      Copy
                    </Typography>
                    <img
                      src={ASSETS.copy_icon_blue}
                      className="w-[13px]"
                      alt=""
                    />
                  </div>
                </CopyTextIcon>
              </div>
            </div>
          </div>
          <TextField
            label="First Name"
            name="firstName"
            value={formik.values.firstName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.firstName && formik.errors.firstName
                ? formik.errors.firstName
                : ""
            }
            onClear={() => formik.setFieldValue("firstName", "")}
          />
          <TextField
            label="Last Name"
            name="lastName"
            value={formik.values.lastName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.lastName && formik.errors.lastName
                ? formik.errors.lastName
                : ""
            }
            onClear={() => formik.setFieldValue("lastName", "")}
          />
          <TextField label="Email" value={user?.email || ""} readOnly />
          <Button
            label="Save Changes"
            variant="primary"
            className="w-full h-[45px]"
            onClick={() => formik.handleSubmit()}
            loading={loading}
          />
        </div>
      </div>
    </DialogLayout>
  );
};

export default Profile;
