import React, { FC, useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../../store';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';

import { IAttessation } from '../../../types/interfaces';
import Typography from '../../../components/Typography/Typography';
import ASSETS from '../../../assets';
import Chip from '../../../components/Chip/Chip';
import Button from '../../../components/Button/Button';
import CopyTextIcon from '../../../components/CopyTextIcon/CopyTextIcon';

dayjs.extend(relativeTime);
dayjs.extend(utc);

interface IProps {
  attestation: IAttessation;
}
const Attestation: FC<IProps> = ({ attestation }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();

  const { user } = useSelector((state: RootState) => state.auth);

  const [attest, setAttest] = useState(attestation);

  const handleAttestationClick = () => {
    if (attest.attestationType === 'doc') {
      dispatch.attesation.setSelectedAttestation(attestation);
      if (user?.walletAddress === attest.creator) {
        navigate(`/document/${attestation.UID}`);
      } else {
        navigate(`/other-user-doc/${attestation.UID}`);
      }
    } else {
      dispatch.attesation.setSelectedAttestation(attestation);
      navigate(`/inspect-text-attestation/${attestation.UID}`);
    }
  };

  useEffect(() => {
    if (attestation.type !== 'text' && attestation.latestAttestation?.UID) {
      setAttest(attestation.latestAttestation);
    } else {
      setAttest(attestation);
    }
  }, [attestation]);
  return (
    <div
      key={attest.UID}
      className='flex items-center justify-between gap-[30px] px-5 pt-6 pb-[30px] border-b border-b-k-border first-of-type:border-t first-of-type:border-t-k-border
  hover:border-none hover:shadow-auth_box cursor-pointer'
      onClick={handleAttestationClick}
    >
      <div className='flex items-start gap-5 flex-grow'>
        <div className='size-[50px] min-w-[50px] rounded-lg border border-k-border overflow-hidden relative'>
          <img src={ASSETS.doc} alt='' />
          {attest.attestationType === 'doc' && (
            <>
              {attest.documentType === 'pdf' ? (
                <img
                  src={ASSETS.pdf}
                  className='absolute right-0 bottom-0'
                  alt=''
                />
              ) : (
                <img
                  src={ASSETS.docx}
                  className='absolute right-0 bottom-0'
                  alt=''
                />
              )}
            </>
          )}
          {attest.attestationType === 'text' && (
            <img
              src={ASSETS.text}
              className='absolute right-0 bottom-0'
              alt=''
            />
          )}
          {/* <img
        src={ASSETS.docx}
        className='absolute right-0 bottom-0'
        alt=''
      /> */}
        </div>
        <div>
          <Typography variant='h5'>{attest.name}</Typography>
          <Typography
            variant='h6'
            color='grey'
            className='line-clamp-2 mt-[10px]'
          >
            {attest.description}
          </Typography>

          {attest?.tags && attest.tags.length > 0 && (
            <div className='flex items-center gap-2 mt-[18px] flex-wrap'>
              {attest.tags.map(t => (
                <Chip label={t} key={t} />
              ))}
            </div>
          )}
        </div>
      </div>
      <div className='min-w-[195px]'>
        <div className='flex items-center justify-between'>
          <Typography variant='body1' color='grey'>
            Last Modified:
          </Typography>
          <Typography variant='body1' className='semibold'>
            {dayjs.unix(+attest.time).utc().format('MMM DD, YYYY')}
          </Typography>
        </div>
        <div className='flex items-center justify-between mt-1'>
          <>
            <Typography variant='body1' color='grey'>
              Versions:
            </Typography>

            <Typography variant='body1' className='semibold'>
              {attestation.totalVersions + 1 || 1}
            </Typography>
          </>
        </div>
        <a href={attest.verifyOnEAS} rel='noopener noreferrer' target='_blank'>
          <Button
            label='Verify on EAS'
            className='w-full justify-center mt-[11px]'
            rightIcon={ASSETS.arrow_up}
            onClick={e => e.stopPropagation()}
          />
        </a>
        <div className="text-center mt-3 z-50">
          <div className="items-center inline-flex mx-auto cursor-pointer">
            <CopyTextIcon textToCopy={attest?.UID || ""}>
              <div className="flex flex-row gap-2">
                <Typography
                  variant="body1"
                  className="text-k-dark-blue font-semibold"
                >
                  Copy UID
                </Typography>
                <img src={ASSETS.copy_icon_blue} alt="" />
              </div>
            </CopyTextIcon>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Attestation;
