import React, { useState } from 'react';

import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import AuthLayout from '../layouts/AuthLayout/AuthLayout';
import Login from '../pages/Login/Login';
import VerifyEmail from '../pages/VerifyEmail/VerifyEmail';
import Logging from '../pages/Logging/Logging';
import DashboardLayout from '../layouts/DashboardLayout/DashboardLayout';
import Documents from '../pages/Documents/Documents';
import AddDocument from '../pages/AddDocument/AddDocument';
import EditorProvider from '../contexts/EditorContext';
import InspectTextAttestation from '../pages/InspectTextAttestation/InspectTextAttestation';
import Comparison from '../pages/Comparison/Comparison';
import RedirectOutlet from './RedirectOutlet/RedirectOutlet';
import PrivateOutlet from './PrivateOutlet/PrivateOutlet';
import OtherUserDoc from '../pages/OtherUserDoc/OtherUserDoc';
import InspectDoc from '../pages/InspectDoc/InspectDoc';
import { RootState } from '../store';
import Typography from '../components/Typography/Typography';
import TextField from '../components/TextField/TextField';
import UploadNewVersion from '../pages/UploadNewVersion/UploadNewVersion';

const Index = () => {
  const navigate = useNavigate();
  const [allow, setAllow] = useState(false);
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { isLoggedIn } = useSelector((state: RootState) => state.auth);
  const location = useLocation();
  return (
    <EditorProvider>
      {!(isLoggedIn || allow) && (
        <div className='w-full h-full bg-white absolute top-0 left-0 z-40 flex items-center justify-center'>
          <div className='min-w-[400px] flex flex-col items-center gap-5'>
            <Typography variant='h2' color='blueDark'>
              Please Enter the Password
            </Typography>
            <div className='w-full'>
              <TextField
                label='Password'
                value={password}
                onChange={e => {
                  setPassword(e.currentTarget.value);
                  setError('');
                }}
                className='w-full'
                showButton
                type='password'
                error={error}
                // onKeyDown={(e) => {
                //   if (e.key === 'Enter') {
                //     setError('');
                //     if (password === 'Docattest@KPMG123') {
                //       setAllow(true);
                //     } else {
                //       setError('Invalid Password');
                //     }
                //     if (!location.pathname.includes('compare-doc')) {
                //       navigate('');
                //     }
                //   }
                // }}
                onSubmit={() => {
                  setError('');
                  if (password === 'Docattest@KPMG123') {
                    setAllow(true);
                  } else {
                    setError('Invalid Password');
                  }
                  if (!location.pathname.includes('compare-doc')) {
                    navigate('');
                  }
                }}
                onClear={() => setPassword('')}
              />
            </div>
          </div>
        </div>
      )}

      <Routes>
        <Route path='/' element={<RedirectOutlet />}>
          <Route path='/' element={<AuthLayout />}>
            <Route path='' index element={<Login />} />
            <Route path='verify-email' element={<VerifyEmail />} />
            <Route path='logging-in' element={<Logging />} />
          </Route>
        </Route>
        <Route path='compare-doc/:id' element={<Comparison />} />
        <Route path='/' element={<PrivateOutlet />}>
          <Route path='/' element={<DashboardLayout />}>
            <Route path='/documents' element={<Documents />} />
            <Route path='/document/:id' element={<UploadNewVersion />} />
            <Route path='/other-user-doc/:id' element={<OtherUserDoc />} />
            <Route path='/inspect-doc/:id' element={<InspectDoc />} />
            <Route path='/add-document' element={<AddDocument />} />
            <Route
              path='/inspect-text-attestation/:id'
              element={<InspectTextAttestation />}
            />
          </Route>
        </Route>
      </Routes>
    </EditorProvider>
  );
};

export default Index;
