import React from 'react';
import Typography from '../../components/Typography/Typography';
import { InfoItemProps } from '../../types/interfaces';
import CopyTextIcon from '../../components/CopyTextIcon/CopyTextIcon';
import { shortenAddress } from '../../utils';

const InfoItem = ({ label, value, icon }: InfoItemProps) => (
  <div>
    <Typography variant='body1' color='grey' className='font-medium'>
      {label}
    </Typography>
    <div className='flex items-center gap-2'>
      <Typography variant='body1' color='black' className='semibold'>
        {shortenAddress(value, 6, 6)}
      </Typography>
      {icon && (
        <CopyTextIcon textToCopy={value || ''}>
          <img src={icon} alt='Copy' />
        </CopyTextIcon>
      )}
    </div>
  </div>
);

export default InfoItem;
