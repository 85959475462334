import React, { useContext, useEffect, useState } from 'react';

import { Link, useParams } from 'react-router-dom';
import { EditorContent } from '@tiptap/react';
import dayjs from 'dayjs';
// @ts-ignore
import html2pdf from 'html2pdf.js';

import utc from 'dayjs/plugin/utc';
import Typography from '../../components/Typography/Typography';
import Button from '../../components/Button/Button';
import ASSETS from '../../assets';
import Chip from '../../components/Chip/Chip';
import { shortenAddress } from '../../utils';
import { IAttestationById } from '../../types/api';
import { getAttestationByUid } from '../../http';
import { EditorContext } from '../../contexts/EditorContext';
import CopyTextIcon from '../../components/CopyTextIcon/CopyTextIcon';

dayjs.extend(utc);

const InspectTextAttestation = () => {
  const id = useParams().id as string;

  const [attestaion, setAttestation] = useState<IAttestationById | null>(null);
  const editor = useContext(EditorContext);

  const [, setLoading] = useState(false);

  const handleGetAttestation = async () => {
    try {
      setLoading(false);
      const { data } = await getAttestationByUid(id);
      setAttestation(data);
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setLoading(true);
    }
  };

  useEffect(() => {
    if (editor) {
      editor.setEditable(false);
    }
    // eslint-disable-next-line
  }, [editor]);

  useEffect(() => {
    if (id) {
      handleGetAttestation();
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (attestaion?.attestationData.text && editor) {
      editor.commands.setContent(attestaion.attestationData.text);
    }
    // eslint-disable-next-line
  }, [attestaion]);

  const handleOpenDocument = async (hideToolbar = false) => {
    try {
      const editorContent = editor?.getHTML();

      if (editorContent) {
        const wrappedContent = `
          <div style="width: 100%; padding: 10px; font-size: 14px; line-height: 1.5; background-color: white;">
            ${editorContent}
          </div>
        `;
        const pdfOptions = {
          margin: 10,
          filename: `output.pdf`,
          image: { type: 'png', quality: 0.98 },
          html2canvas: {
            scale: 2,
            letterRendering: true,
            useCORS: true,
            allowTaint: true,
          },
          jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
          download: false,
        };

        const pdfBlob = await html2pdf()
          .from(wrappedContent)
          .set(pdfOptions)
          .output('blob');
        const pdfUrl = URL.createObjectURL(pdfBlob);
        const finalUrl = hideToolbar ? pdfUrl : `${pdfUrl}#toolbar=0`;
        window.open(finalUrl, '_blank');
      }
    } catch (err: any) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='flex-grow bg-white shadow-auth_box rounded-[15px] px-[50px] pb-[50px]  flex flex-col mb-[81px]'>
      <div className='flex'>
        <Link
          to='/documents'
          className='flex items-center gap-2 cursor-pointer py-4 ml-[-14px]'
        >
          <img src={ASSETS.arrow_left} alt='arrow-left' />
          <Typography variant='body1' color='blue' className='semibold'>
            Dashboard
          </Typography>
        </Link>
      </div>
      <div className='flex flex-grow flex-col'>
        <div className='flex flex-grow h-full gap-[50px] items-stretch '>
          <div className='flex flex-grow  bg-k-grey-200 rounded-xl p-[22px] border border-solid border-k-grey-200'>
            <div className='h-full  overflow-auto bg-white pt-10 pl-10 pr-10 w-full gap-4 max-h-[600px] '>
              <EditorContent
                editor={editor}
                content={attestaion?.attestationData.text || ''}
              />
            </div>
          </div>
          <div className='flex flex-grow max-w-[328px] min-w-[328px]'>
            <div className='flex flex-col bg-white  w-full'>
              <Typography variant='h3' className='!font-[382] leading-[30px]'>
                {attestaion?.attestationData?.name}
              </Typography>
              {!attestaion?.isUserAttestation && (
                <div className='flex items-center gap-2 mt-[15px]'>
                  <div className='w-[30px] aspect-square h-[30px] rounded-full bg-[#D4EBFF] border border-[#ADD2FD] grid place-items-center'>
                    <Typography
                      variant='body1'
                      className='semibold leading-[17px] uppercase'
                    >
                      {attestaion?.attestationData.email.slice(0, 2)}
                    </Typography>
                  </div>
                  <div>
                    <Typography className='semibold leading-[17px]'>
                      {attestaion?.attestationData.email}
                    </Typography>
                    <Typography
                      className='semibold leading-[17px]'
                      color='grey'
                    >
                      Owner
                    </Typography>
                  </div>
                </div>
              )}
              <Typography
                variant='h6'
                color='black'
                className='leading-[19px] font-medium mt-[15px]'
              >
                {attestaion?.attestationData?.description}
                {/* <div className='flex items-center gap-2 mt-2'>
                <Typography variant='body1' color='blue' className='semibold'>
                  Read More
                </Typography>
                <img src={ASSETS.add_blue} alt='add-blue' className='' />
              </div> */}
              </Typography>
              {attestaion?.attestationData?.tags &&
                attestaion?.attestationData?.tags.length > 0 && (
                  <div className='flex items-center gap-2 mt-[22px]  mb-5 flex-wrap'>
                    {attestaion?.attestationData.tags.map(t => (
                      <Chip label={t} key={t} />
                    ))}
                  </div>
                )}
              <div className='w-full grid md:grid-cols-2 sm:grid-cols-2 grid-cols-1 gap-4 mb-6'>
                <div className='flex w-full flex-col'>
                  <Typography
                    variant='body1'
                    color='grey'
                    className='font-medium'
                  >
                    Date Created:
                  </Typography>
                  {attestaion?.attestationData && (
                    <Typography variant='body1' className='semibold'>
                      {dayjs
                        .unix(+attestaion.attestationData.time)
                        .utc()
                        .format('MMM DD, YYYY @ hh:mm A')}
                    </Typography>
                  )}
                </div>
                <div className='flex w-full flex-col'>
                  <Typography
                    variant='body1'
                    color='grey'
                    className='font-medium'
                  >
                    {attestaion?.isUserAttestation
                      ? 'Your Wallet Address:'
                      : "Owner's Wallet Address:"}
                  </Typography>
                  <Typography
                    variant='body1'
                    className='flex items-center gap-2 semibold'
                  >
                    {shortenAddress(attestaion?.attestationData?.creator || '')}
                    <CopyTextIcon
                      textToCopy={attestaion?.attestationData?.creator || ''}
                    >
                      <img
                        src={ASSETS.copy_black}
                        alt='copy_black'
                        className='cursor-pointer opacity-60'
                      />
                    </CopyTextIcon>
                  </Typography>
                </div>
                <div className='flex w-full flex-col'>
                  <Typography
                    variant='body1'
                    color='grey'
                    className='font-medium'
                  >
                    UID:
                  </Typography>
                  <Typography
                    variant='body1'
                    className='flex items-center gap-2 semibold'
                  >
                    {shortenAddress(attestaion?.attestationData?.UID || '')}
                    <CopyTextIcon
                      textToCopy={attestaion?.attestationData?.UID || ''}
                    >
                      <img
                        src={ASSETS.copy_black}
                        alt='copy_black'
                        className='cursor-pointer opacity-60'
                      />
                    </CopyTextIcon>
                  </Typography>
                </div>
              </div>
              <div className='mt-auto'></div>
              <div className='flex flex-col w-full gap-3'>
                <Button
                  label='Open Attestation in New Window'
                  className='w-full justify-center py-2 text-sm'
                  rightIcon={ASSETS.arrow_up}
                  onClick={() => handleOpenDocument(false)}
                />
                <Button
                  label='Print Attestation'
                  className='w-full justify-center py-2 text-sm'
                  onClick={() => handleOpenDocument(true)}
                />
                <div className='flex justify-center'>
                  <a
                    href={attestaion?.attestationData?.verifyOnEAS}
                    rel='noopener noreferrer'
                    target='_blank'
                  >
                    <div className='items-center gap-2 inline-flex mx-auto cursor-pointer'>
                      <Typography
                        variant='body1'
                        color='blue'
                        className='semibold'
                      >
                        Verify on EAS
                      </Typography>
                      <img src={ASSETS.arrow_up_blue} alt='' />
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InspectTextAttestation;
