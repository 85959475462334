import React, { useEffect, useState } from "react";
import { EditorContent } from "@tiptap/react";
import Typography from "../Typography/Typography";

import ASSETS from "../../assets";
import HighlightC from "./HighlightC";
import TextColor from "./TextColor";
import { useTipTap } from "../../contexts/EditorContext";

const Editor = () => {
  const editor = useTipTap();
  const [wordCount, setWordCount] = useState(0);
  const [activeIcons, setActiveIcons] = useState<any>({});
  useEffect(() => {
    if (!editor) return;

    const updateWordCount = () => {
      const text = editor.getText();
      const words = text.split("").length;
      setWordCount(words);
    };

    editor.on("update", updateWordCount);

    return () => {
      editor.off("update", updateWordCount);
    };
  }, [editor]);

  const toggleActiveIcon = (icon: any) => {
    setActiveIcons((prev: any) => ({
      ...prev,
      [icon]: !prev[icon],
    }));
  };
  return (
    <div className="h-full flex flex-col ">
      <div className="bg-white p-4 flex flex-wrap gap-4 border-b border-b-k-border justify-center">
        <div className="flex itemse-center justify-center gap-[16px_10px] flex-wrap [&_img]:cursor-pointer max-w-[330px]">
          <img
            className="cursor-pointer hover:bg-gray-200 rounded transition-colors"
            src={ASSETS.undeo}
            onClick={() => editor?.chain().undo().focus().run()}
            alt=""
          />
          <img
            className="cursor-pointer hover:bg-gray-200 rounded transition-colors"
            src={ASSETS.redo}
            onClick={() => editor?.chain().redo().focus().run()}
            alt=""
          />
          <img
            className={` ml-4 cursor-pointer hover:bg-gray-200 rounded transition-colors ${
              activeIcons.bold ? "bg-gray-300" : ""
            }`}
            src={ASSETS.bold}
            onClick={() => {
              editor?.chain().toggleBold().focus().run();
              toggleActiveIcon("bold");
            }}
            alt=""
          />
          <img
            className={`cursor-pointer hover:bg-gray-200 rounded transition-colors ${
              activeIcons.italic ? "bg-gray-300" : ""
            }`}
            src={ASSETS.italic}
            onClick={() => {
              editor?.chain().toggleItalic().focus().run();
              toggleActiveIcon("italic");
            }}
            alt=""
          />
          <img
            className={`cursor-pointer hover:bg-gray-200 rounded transition-colors ${
              activeIcons.underline ? "bg-gray-300" : ""
            }`}
            src={ASSETS.underline}
            onClick={() => {
              editor?.chain().toggleUnderline().focus().run();
              toggleActiveIcon("underline");
            }}
            alt=""
          />
          <img
            className={`cursor-pointer hover:bg-gray-200 rounded transition-colors ${
              activeIcons.strike ? "bg-gray-300" : ""
            }`}
            src={ASSETS.strike}
            onClick={() => {
              editor?.chain().toggleStrike().focus().run();
              toggleActiveIcon("strike");
            }}
            alt=""
          />
          {editor && <HighlightC editor={editor} />}
          {editor && <TextColor editor={editor} />}
          <img
            className="cursor-pointer hover:bg-gray-200 rounded transition-colors"
            src={ASSETS.align_left}
            onClick={() => {
              editor?.chain().focus().setTextAlign("left").run();
            }}
            alt=""
          />
          <img
            className="cursor-pointer hover:bg-gray-200 rounded transition-colors"
            src={ASSETS.align_center}
            onClick={() => editor?.chain().focus().setTextAlign("center").run()}
            alt=""
          />
          <img
            className="cursor-pointer hover:bg-gray-200 rounded transition-colors"
            src={ASSETS.align_right}
            onClick={() => editor?.chain().focus().setTextAlign("right").run()}
            alt=""
          />
          <img
            className="cursor-pointer hover:bg-gray-200 rounded transition-colors"
            src={ASSETS.justify_text}
            onClick={() =>
              editor?.chain().focus().setTextAlign("justify").run()
            }
            alt=""
          />
          <img
            src={ASSETS.spacing}
            className=" ml-4 cursor-pointer hover:bg-gray-200 rounded transition-colors"
            alt=""
          />
          <img
            src={ASSETS.indent_left}
            onClick={() => {
              try {
                editor?.chain().focus().outdent().run();
              } catch (err: any) {}
            }}
            alt=""
          />
          <img
            src={ASSETS.indent_right}
            onClick={() => {
              try {
                editor?.chain().focus().indent().run();
              } catch (err: any) {}
            }}
            alt=""
          />
          <img
            src={ASSETS.remove_style}
            onClick={() => editor?.chain().focus().unsetAllMarks().run()}
            alt=""
          />
        </div>
      </div>
      <div className="flex-grow p-6 overflow-auto h-0">
        <EditorContent editor={editor} placeholder="Start writing" />
      </div>
      <div className="h-10 bg-white flex items-center justify-between px-4">
        <Typography variant="body1" color="grey" className="font-medium">
          {wordCount} words
        </Typography>
        <img src={ASSETS.edge} alt="" />
      </div>
    </div>
  );
};

export default Editor;
