import React, { FC } from 'react';

import { useDropzone } from 'react-dropzone';
import Typography from '../Typography/Typography';
import ASSETS from '../../assets';
import ClickAwayListener from 'react-click-away-listener';
import { twMerge } from 'tailwind-merge';

interface IProps {
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  setFile: React.Dispatch<React.SetStateAction<File | null>>;
}

const FilePicker: FC<IProps> = ({ setShow, setFile }) => {
  const { getRootProps, getInputProps, isDragAccept, isDragReject } =
    useDropzone({
      accept: {
        'application/pdf': [],
        'application/msword': [],
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          [],
      },
      onDropAccepted: files => {
        if (files.length) {
          const file = files[0];
          setFile(file);
        }
        setShow(false);
      },
      onDragEnter: e => {
        e.preventDefault();
      },
    });
  return (
    <ClickAwayListener onClickAway={() => setShow(false)}>
      <div
        className={twMerge(
          'w-full h-full bg-k-dark-blue-85 z-50 rounded-[10px] border-2 border-k-dark-blue',
          isDragAccept && 'border-k-success',
          isDragReject && 'border-k-error'
        )}
        {...getRootProps()}
      >
        <div className='h-full w-full flex flex-col items-center justify-center gap-6'>
          <input {...getInputProps()} />
          <img src={ASSETS.upload_files} className='w-11' alt='' />
          <Typography variant='h5' color='white'>
            Drop file here to upload.
          </Typography>
        </div>
      </div>
    </ClickAwayListener>
  );
};

export default FilePicker;
