import React, { FC } from 'react';

interface IProps {
  onColorChange: (clg: string) => void;
}

// const colors = [
//   '#212529',
//   '#845ef7',
//   '#339af0',
//   '#22b8cf',
//   '#51cf66',
//   '#fcc419',
//   '#ff6b6b',
//   '#f06595',
//   '#ffffff',
//   '#5f3dc4',
//   '#1864ab',
//   '#0b7285',
//   '#2b8a3e',
//   '#e67700',
//   '#c92a2a',
//   '#c2255c',
//   '#00000000',
// ];

const colors = [
  'rgb(33, 37, 41)', // #212529
  'rgb(132, 94, 247)', // #845ef7
  'rgb(51, 154, 240)', // #339af0
  'rgb(34, 184, 207)', // #22b8cf
  'rgb(81, 207, 102)', // #51cf66
  'rgb(252, 196, 25)', // #fcc419
  'rgb(255, 107, 107)', // #ff6b6b
  'rgb(240, 101, 149)', // #f06595
  'rgb(255, 255, 255)', // #ffffff
  'rgb(95, 61, 196)', // #5f3dc4
  'rgb(24, 100, 171)', // #1864ab
  'rgb(11, 114, 133)', // #0b7285
  'rgb(43, 138, 62)', // #2b8a3e
  'rgb(230, 119, 0)', // #e67700
  'rgb(201, 42, 42)', // #c92a2a
  'rgb(194, 37, 92)', // #c2255c
  'rgba(0, 0, 0, 0)', // #00000000 (transparent)
];

const ColorPicker: FC<IProps> = ({ onColorChange }) => {
  const handleClick = (clr: string) => {
    onColorChange(clr);
  };

  return (
    <div
      className='shadow-auth_box border border-k-border rounded'
      onMouseDown={e => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <div className='w-[198px] bg-k-grey-200 flex items-center p-1 flex-wrap gap-2'>
        {colors.map(c => (
          <div
            key={c}
            className='size-5 cursor-pointer rounded  border border-k-border'
            style={{ background: c }}
            onClick={() => {
              handleClick(c);
            }}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default ColorPicker;
