import React, { useCallback, useState } from 'react';

interface CopyTextIconProps {
  textToCopy: string;
  onCopy?: () => void;
  children: React.ReactNode;
}

const CopyTextIcon = ({ textToCopy, onCopy, children }: CopyTextIconProps) => {
  const [showToaster, setShowToaster] = useState(false);

  const handleCopy = async (e:React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    try {
      e.stopPropagation();
      await navigator.clipboard.writeText(textToCopy);
      setShowToaster(true);

      if (onCopy) {
        onCopy();
      }

      setTimeout(() => setShowToaster(false), 1000);
    } catch (err) {
      console.error('Failed to copy text:', err);
    }
  };

  return (
    <div onClick={handleCopy} style={{ position: 'relative', cursor: 'pointer' }}>
      {children}
      {showToaster && (
        <div
          style={{
            position: 'absolute',
            top: '-20px', // Positioned above the button
            left: '50%',
            transform: 'translateX(-50%)',
            backgroundColor: '#333',
            color: '#fff',
            padding: '5px 10px',
            borderRadius: '5px',
            fontSize: '12px',
            whiteSpace: 'nowrap',
            zIndex: 1000,
          }}
        >
          Copied!
        </div>
      )}
    </div>
  );
};

export default CopyTextIcon;
