import React from 'react';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import Typography from '../../components/Typography/Typography';
import Button from '../../components/Button/Button';
import { IAttessation } from '../../../src/types/interfaces';
import ASSETS from '../../assets';
import CopyTextIcon from '../../components/CopyTextIcon/CopyTextIcon';

dayjs.extend(utc);

const DocumentHistory = ({
  documentData,
  isLatest,
}: {
  documentData: IAttessation;
  isLatest: any;
}) => (
  <div className='flex justify-between gap-[30px] px-5 pt-6 pb-[30px] border-b border-b-k-border hover:border-none hover:shadow-auth_box cursor-pointer'>
    <div className='flex items-start gap-5 flex-grow'>
      <div className='size-[50px] min-w-[50px] rounded-lg border border-k-border overflow-hidden relative'>
        <img src={ASSETS.doc} alt='Document' />
        {documentData.attestationType === 'doc' && (
          <>
            {documentData.documentType === 'pdf' ? (
              <img
                src={ASSETS.pdf}
                className='absolute right-0 bottom-0'
                alt=''
              />
            ) : (
              <img
                src={ASSETS.docx}
                className='absolute right-0 bottom-0'
                alt=''
              />
            )}
          </>
        )}
        {documentData.attestationType === 'text' && (
          <img src={ASSETS.text} className='absolute right-0 bottom-0' alt='' />
        )}
      </div>
      <div className='flex flex-col min-w-[550px] gap-[10px]'>
        <div>
          <Typography variant='body1' color='grey' className='font-medium'>
            UID:
          </Typography>
          <div className='flex items-center ml-1'>
            <Typography
              variant='h6'
              color='black'
              className='line-clamp-2 font-medium'
            >
              {documentData.UID}
            </Typography>
            <CopyTextIcon textToCopy={documentData.UID || ''}>
              <img src={ASSETS.copy_grey} alt='' className='ml-1' />
            </CopyTextIcon>
          </div>
          <Typography variant='body1' color='grey' className='font-medium'>
            Doc Hash:
          </Typography>
          <div className='flex items-center ml-1'>
            <Typography
              variant='h6'
              color='black'
              className='line-clamp-2 font-medium'
            >
              {documentData.docHash}
            </Typography>
            <CopyTextIcon textToCopy={documentData.docHash || ''}>
              <img src={ASSETS.copy_grey} alt='' className='ml-1' />
            </CopyTextIcon>
          </div>
        </div>
        <div className='flex flex-row w-169px min-w-[169px] gap-3'>
          <div className=''>
            <CopyTextIcon textToCopy={documentData.verifyOnEAS || ''}>
              <Button
                label='Share Verification Link'
                className='justify-center h-[35px] text-sm whitespace-nowrap'
                rightIcon={ASSETS.arrow_up}
              />
            </CopyTextIcon>
          </div>
          <a
            href={documentData.verifyOnEAS}
            target='_blank'
            rel='noopener noreferrer'
            className='w-full h-auto flex items-center justify-start gap-1'
          >
            <Typography
              variant='body1'
              color='blue'
              className='semibold leading-[17px]'
            >
              Verify on EAS
            </Typography>
            <img src={ASSETS.arrow_up_blue} alt='' />
          </a>
        </div>
      </div>
    </div>
    <div className='flex flex-col gap-[10px]'>
      <div>
        <Typography variant='body1' color='grey' className='font-medium'>
          Uploaded:
        </Typography>
        <Typography
          variant='body1'
          color='black'
          className='semibold whitespace-nowrap'
        >
          {dayjs.unix(+documentData.time).format('MMM DD,YYYY @ hh:mm A')}
        </Typography>
      </div>
      <div>
        <Typography variant='body1' color='grey' className='font-medium'>
          Version:
        </Typography>
        <Typography variant='body1' color='black' className='semibold'>
          {isLatest ? 'Latest' : documentData.version + 1}
        </Typography>
      </div>
      <div>
        <Typography variant='body1' color='grey' className='font-medium'>
          File Size:
        </Typography>
        <Typography variant='body1' color='black' className='semibold'>
          {documentData.size}k
        </Typography>
      </div>
    </div>
  </div>
);

export default DocumentHistory;
